import styled from "styled-components/macro";
import orbitingAsteroids1 from "./assets/imgs/orbiting_asteroids_1.svg";
import orbitingAsteroids2 from "./assets/imgs/orbiting_asteroids_2.svg";

function OrbitingAsteroids() {
  return (
    <StyledOrbitingAsteroids>
      <div className="container">
        <img src={orbitingAsteroids1} alt="" className="planet" />
        <img src={orbitingAsteroids2} alt="" className="asteroids" />
      </div>
    </StyledOrbitingAsteroids>
  );
}

export default OrbitingAsteroids;

const StyledOrbitingAsteroids = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    height: 170px;
    aspect-ratio: 1;
    position: relative;
  }

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes orbit {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .asteroids {
    animation: orbit 10s infinite linear reverse;
  }
`;
