import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { SmallButton } from "../TheGame/Button";
import AdminGroup from "./AdminGroup";
import { btnColors, btnIcons } from "../shared-styles";
import { useState, useMemo } from "react";
import Loading from "../Loading";
import AppHeader from "../AppHeader";
import SlideDown from "../SlideDown";
import SearchInput from "../SearchInput";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { deleteItems } from "./adminUtils";
import useIsAdmin from "../useIsAdmin";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const Admin = () => {
  const navigate = useNavigate();
  const isAdmin = useIsAdmin(auth);
  const [search, setSearch] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedItems, setSelectedItems] = useState<
    { id: string; type: string }[]
  >([]);

  const groups = useMemo(() => {
    const itemBuilder = (type: string, id: string) => {
      const selected = selectedItems.find(
        ({ id: itemId, type: itemType }) => itemId === id && itemType === type
      );
      const btn = isDeleting ? (
        <SmallButton
          key={id}
          buttonText={id}
          onClick={() =>
            setSelectedItems((items) =>
              selected
                ? items.filter((item) => item !== selected)
                : [...items, { id, type }]
            )
          }
          icon={selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
          color={btnColors[type]}
          selected={!!selected}
        ></SmallButton>
      ) : (
        <SmallButton
          key={id}
          buttonText={id}
          onClick={() => navigate(`${type}/${id}`)}
          icon={btnIcons[type]}
          color={btnColors[type]}
        ></SmallButton>
      );
      return (
        <SlideDown key={id} delay={Math.random() * 0.15} duration={0.25}>
          {btn}
        </SlideDown>
      );
    };
    return {
      classes: {
        entityName: "class",
        itemBuilder: (id: string) => itemBuilder("class", id),
        onLoaded: () => setGroupsLoaded((g) => ({ ...g, classes: true })),
        idFilter: search,
      },
      ...(isAdmin && {
        folders: {
          entityName: "folder",
          itemBuilder: (id: string) => itemBuilder("folder", id),
          onLoaded: () => setGroupsLoaded((g) => ({ ...g, folders: true })),
          idFilter: search,
        },
        sets: {
          entityName: "set",
          itemBuilder: (id: string) => itemBuilder("set", id),
          onLoaded: () => setGroupsLoaded((g) => ({ ...g, sets: true })),
          additionalActions: [
            <SmallButton
              key={"powerups"}
              buttonText="Powerup Items"
              onClick={() => {
                navigate(`powerups`);
              }}
              color="gold"
              icon={btnIcons.powerups}
            ></SmallButton>,
          ],
          idFilter: search,
        },
        users: {
          entityName: "user",
          itemBuilder: (id: string) => itemBuilder("user", id),
          onLoaded: () => setGroupsLoaded((g) => ({ ...g, users: true })),
          idFilter: search,
        },
      }),
    };
  }, [isAdmin, isDeleting, navigate, search, selectedItems]);

  const [groupsLoaded, setGroupsLoaded] = useState(
    Object.keys(groups).reduce((obj, group) => ({ ...obj, [group]: false }), {})
  );

  const isLoaded = useMemo(
    () => Object.values(groupsLoaded).every((g) => g),
    [groupsLoaded]
  );

  return (
    <StyledAdmin>
      <AppHeader>
        <Loading isLoading={!isLoaded}>
          <div className="admin-groups">
            <div className="admin-actions">
              {isAdmin && !isDeleting && (
                <SmallButton
                  color={btnColors.delete}
                  buttonText="Bulk Delete"
                  onClick={() => {
                    setIsDeleting((x) => !x);
                  }}
                  icon={DeleteIcon}
                />
              )}
              {isDeleting && (
                <SmallButton
                  disabled={selectedItems.length === 0}
                  color={btnColors.delete}
                  onClick={() =>
                    deleteItems(selectedItems).then(() => {
                      setIsDeleting(false);
                      setSelectedItems([]);
                    })
                  }
                  buttonText={
                    selectedItems.length === 0
                      ? "Delete"
                      : `Delete ${selectedItems.length} Items`
                  }
                  icon={CheckIcon}
                />
              )}
              {isDeleting && (
                <SmallButton
                  onClick={() => {
                    setIsDeleting(false);
                    setSelectedItems([]);
                  }}
                  buttonText="Cancel"
                  icon={ClearIcon}
                />
              )}
            </div>
            <SearchInput onSearchValue={setSearch} />
            <AdminGroup collapse={false} canAdd {...groups.classes}></AdminGroup>
            {isAdmin && (
              <>
                <AdminGroup collapse={true} canAdd {...groups.folders!}></AdminGroup>
                <AdminGroup collapse={true} canAdd {...groups.sets!}></AdminGroup>
                <AdminGroup collapse={true} canAdd {...groups.users!}></AdminGroup>
              </>
            )}
          </div>
        </Loading>
      </AppHeader>
    </StyledAdmin>
  );
};

export default Admin;

const StyledAdmin = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  margin: auto;

  .admin-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .admin-groups {
    margin-block: 20px;
  }
`;
