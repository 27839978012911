import React from 'react'
import styled from 'styled-components/macro'

interface AsteroidFooterProps {
    totalAsteroids: number
    discoveredHues: number[]
}

const AsteroidFooter: React.FC<AsteroidFooterProps> = ({
    totalAsteroids,
    discoveredHues
}) => {
    return (
        <StyledAsteroidFooter>
            {Array.from({ length: totalAsteroids }).map((_, index) => {
                const isDiscovered = index < discoveredHues.length;
                const isLastDiscovered = index === discoveredHues.length - 1;
                const backgroundColor = isDiscovered
                    ? `hsl(${discoveredHues[index]}, 100%, 50%)`
                    : 'transparent';
                const width = isDiscovered ? '100%' : 0;
                const borderRadius = isLastDiscovered ? '7px' : '0';

                return (
                    <div
                        key={index}
                        className="progress-segment">
                        <div className="bar"
                            style={{
                                backgroundColor, width,
                                borderTopRightRadius: borderRadius,
                                borderBottomRightRadius: borderRadius
                            }}
                        ></div>
                    </div>
                );
            })}
        </StyledAsteroidFooter>
    );
}

const StyledAsteroidFooter = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15px;
    display: flex;
    
    .progress-segment {
        flex: 1;
        .bar {
            height: 100%;
            transition: width 0.2s ease-in-out;
            
        }
    }
`;

export default AsteroidFooter