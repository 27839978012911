import styled from "styled-components/macro";
import { getAuth } from "firebase/auth";
import { niceColors, btnColors } from "./shared-styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getNameFromEmail } from "./utils";
import useIsTeacher from "./useIsTeacher";
import { SmallButton } from "./TheGame/Button";
import { useState } from "react";
import LoginModal from "./LoginModal";
import Logo from "./Logo";

const auth = getAuth();

interface Props {
  children: React.ReactNode;
}

function AppHeader({ children }: Props) {
  const [user] = useAuthState(auth);
  const [showLogin, setShowLogin] = useState(false);
  const isTeacher = useIsTeacher(auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathRoot = pathname.match(/^\/([^/]+)/)?.[1]?.toLowerCase();

  return (
    <StyledAppHeader>
      <div className="app-header">
        <div className="nwe">
          <Logo />
          <button className="nwe-title-btn" onClick={() => navigate("/home")}>
            <h2 className="nwe-title">NWE Space</h2>
          </button>
        </div>
        {user && pathname !== "/home" && (
          <SmallButton
            className="home-btn"
            onClick={() => navigate("/home")}
            buttonText="Home"
            color={btnColors.home}
          ></SmallButton>
        )}
        <div className="spacer"></div>
        {user && (
          <>
            <div className="user-name">{getNameFromEmail(user!)}</div>
            <div className="app-header-actions">
              {isTeacher && pathRoot !== "admin" && (
                <SmallButton
                  className="admin-btn"
                  onClick={() => navigate("/admin")}
                  buttonText="Admin"
                  color="orange"
                ></SmallButton>
              )}
              <SmallButton
                className="log-out-btn"
                onClick={() => { auth.signOut(); navigate("/") }}
                buttonText="Log Out"
              ></SmallButton>
            </div>
          </>
        )}
        {!user && pathRoot === "home" &&
          <div className="app-header-actions">
            <SmallButton
              className="log-in-btn"
              onClick={() => setShowLogin(true)}
              buttonText="Log In"
            ></SmallButton>
            {showLogin && <LoginModal cancel={() => setShowLogin(false)} loginText="Ready to Launch" />}
          </div>}
      </div>
      <div className="app-content-container">
        <div className="app-content">{children}</div>
      </div>
    </StyledAppHeader>
  );
}

export default AppHeader;

const StyledAppHeader = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .app-header {
    background-color: ${niceColors.darkDarkPurple};
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: solid 1px rgb(53, 53, 53);

    .nwe {
      margin-left: 10px;
      display: flex;
      align-items: center;

      .nwe-title-btn:hover {
        opacity: 1;
      }
      .nwe-title-btn:active > * {
        transform: scale(1);
      }

      .nwe-title {
        text-transform: uppercase;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    .spacer {
      flex: 1;
    }
    .user-name {
      margin-right: 20px;
      text-transform: none;
    }
    .app-header-actions {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    @media (max-width: 500px) {
      .app-header-actions {
        flex-direction: column;
      }
    }
  }

  .app-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: min(900px, 100%);
    align-items: center;

    .app-content {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      width: min(900px, 100%);
    }
  }
`;
