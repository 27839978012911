import { User } from "firebase/auth";

export function getNameFromEmail(user: User) {
    return user!.email!.match(/^(.*)@newworldenglish.com$/)![1]
}

export function getDisplayTime(milliseconds: number) {
    return `${(milliseconds / 1000).toFixed(1)}s`;
}

export function shuffleArray<T>(array: T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function shuffleThorough<T>(array: T[]): T[] {
    if (array.length <= 1) return array

    let shuffledArray: T[]
    do {
        shuffledArray = [...array]

        // Fisher-Yates shuffle
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            // Replace destructuring with temp variable
            const temp = shuffledArray[i]
            shuffledArray[i] = shuffledArray[j]
            shuffledArray[j] = temp
        }
    } while (isAnyElementInPlace(array, shuffledArray)) // Repeat until no element is in its original position

    return shuffledArray
}

// Check if any element is in the same position as in the original array
function isAnyElementInPlace<T>(originalArray: T[], shuffledArray: T[]): boolean {
    return shuffledArray.some((value, index) => Object.is(value, originalArray[index]))
}

export function getPromptImgUrl(id: string) {
    return `https://storage.googleapis.com/new-world-english.appspot.com/images/${id}_200x200`
}

export function trimVal(val: string) {
    return val.replaceAll(/\([^)]*\)/g, '').replaceAll(/^[\s\p{P}\p{S}]+|[\s\p{P}\p{S}]+$/gu, "")
}
