import styled from "styled-components/macro";
import SvgIcon from "@mui/material/SvgIcon";
import SaveButton from "./SaveButton";
import BackButton from "../BackButton";

interface Props {
  save?: () => Promise<void>;
  saveDisabled?: boolean;
  icon: typeof SvgIcon;
  title: string | React.ReactNode;
  backTo?: string;
  saveButtonRef?: React.RefObject<HTMLButtonElement>;
}

const EditHeader = ({ save, saveDisabled, icon, title, backTo, saveButtonRef }: Props) => {
  return (
    <StyledEditHeader className="edit-header">
      <div className="header">
        <div className="left">
          <BackButton to={backTo} />
        </div>
        <h1 className="header-title">
          <SvgIcon component={icon} className="header-title-icon"></SvgIcon>{" "}
          {title}
        </h1>
        <div className="right">
          {save && (
            <SaveButton 
              ref={saveButtonRef}
              save={save} 
              disabled={!!saveDisabled}
            ></SaveButton>
          )}
        </div>
      </div>
    </StyledEditHeader>
  );
};

export default EditHeader;

const StyledEditHeader = styled.div`
  .header {
    display: flex;
    width: 100%;
    align-items: center;

    .header-title {
      display: flex;
      align-items: center;
      margin-left: 50px;
      margin-right: 50px;

      .header-title-icon {
        margin-right: 10px;
      }
    }
    .left,
    .right {
      flex: 1;
      display: flex;
    }
    .right {
      justify-content: flex-end;
    }
  }
`;
