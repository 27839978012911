// Asteroid2.tsx
interface Asteroid2Props {
  hue: number
}

export default function Asteroid2({ hue }: Asteroid2Props) {
  // const primaryColor = `hsl(${hue}, 51%, 52%)`     // #6e4db8
  // const secondaryColor = `hsl(${hue}, 79%, 74%)`   // #aaaded
  // const darkColor = `hsl(${hue}, 58%, 35%)`        // #3e248d
  // const deepColor = `hsl(${hue}, 80%, 25%)`        // #260c73
  const primaryColor = `hsl(${hue}, 99%, 69%)`      // Increased saturation, same lightness
  const secondaryColor = `hsl(${(hue+22)%360}, 99%, 84%)`    // Increased saturation, same lightness
  const darkColor = `hsl(${(hue-12)%360}, 100%, 31%)`         // Increased saturation, same lightness
  const deepColor = `hsl(${(hue-12)%360}, 90%, 21%)`         // Increased saturation, same lightness

  return (
    <svg className="asteroid2" width="100%" height="100%" viewBox="0 0 77 76" xmlns="http://www.w3.org/2000/svg">
      <path 
        id="path2" 
        style={{ fill: primaryColor }} 
        d="M 18.932793,9.00666 C 7.2730932,10.576592 12.968786,23.899234 11.880859,25.142578 -1.6577815,36.931327 9.0253906,51.207031 9.0253906,51.207031 l 0.091797,0.111328 C 15.287861,69.36988 39.335938,67.46875 39.335938,67.46875 c 12.709744,4.973378 20.05664,-2.605469 20.05664,-2.605469 13.170242,-2.118291 7.554688,-13.285156 7.554688,-13.285156 3.315586,-6.446972 0.261718,-10.679687 0.261718,-10.679687 0,0 6.439929,-3.745014 -1.783203,-10.904297 -1.323886,-5.031101 -7.58789,-1.904297 -7.58789,-1.904297 C 57.101094,24.682159 48.351562,23.669922 48.351562,23.669922 50.377754,5.8025999 29.931641,8.5644531 29.931641,8.5644531 21.550578,4.5005584 21.260182,8.6932864 18.932793,9.00666 Z" 
      />
      <path 
        id="path1" 
        style={{ fill: secondaryColor }} 
        d="m 65.384747,29.957155 c 8.288964,7.183769 1.823479,10.940874 1.823479,10.940874 0,0 3.055089,4.233405 -0.260497,10.680377 0,0 5.615829,11.167056 -7.554413,13.285347 0,0 -7.348524,7.578348 -20.058269,2.60497 0,0 -24.046979,1.900707 -30.217652,-16.150814 0,0 4.682593,9.004071 26.0497,12.503856 l -0.520994,-3.646958 c 14.183338,12.249247 12.767064,-5.346734 12.767064,-5.346734 2.855088,2.855088 1.299774,8.733195 1.299774,8.733195 5.618075,-1.013095 8.596401,-3.907455 8.596401,-3.907455 4.51288,0.644697 5.344023,-0.149884 7.554413,-2.083976 0.184199,-3.776084 -1.902488,-10.05915 -0.520994,-14.848329 -2.39459,-0.184199 -3.27856,-0.810383 -3.646958,-1.823479 0,0 10.214922,0.755775 4.688946,-10.940874 z" 
      />
      <path 
        id="path3" 
        style={{ fill: darkColor }} 
        d="m 47.98389,26.432585 c 0,0 17.314724,11.880848 0.920996,26.800983 -13.262342,-3.776084 -13.814939,4.881278 -13.814939,4.881278 -0.276299,-2.118291 -4.420781,-2.30249 -4.420781,-2.30249 1.197295,-9.302059 -11.144052,-6.262773 -9.670458,-1.841992 -4.052382,-4.144482 -7.460067,-4.052382 -7.460067,-4.052382 -0.736797,-2.21039 2.855087,-1.841992 2.855087,-1.841992 3.960283,-6.262773 -3.499784,-4.144482 -3.499784,-4.144482 C 10.591454,48.812787 9.6704578,43.194711 9.6704578,43.194711 4.7891791,32.971656 13.63074,26.248385 13.63074,26.248385 14.551736,21.551306 12.709745,6.4469719 28.274577,8.933661 c 0,0 20.906608,1.841992 19.709313,17.498924 z" 
      />
      <ellipse 
        id="path6" 
        style={{ fill: darkColor }} 
        cx="9.7990847" 
        cy="72.154839" 
        rx="6.3918157" 
        ry="5.110352" 
        transform="matrix(0.8933125,-0.44943607,0.46200465,0.8868775,0,0)" 
      />
      <ellipse 
        id="path7" 
        style={{ fill: darkColor }} 
        cx="25.972086" 
        cy="55.259758" 
        rx="2.6708884" 
        ry="2.0261912" 
      />
      <ellipse 
        id="path8" 
        style={{ fill: darkColor }} 
        cx="8.3422737" 
        cy="71.675201" 
        rx="3.7368476" 
        ry="4.4177775" 
        transform="matrix(0.36857977,-0.92959612,0.82355318,0.56723907,0,0)" 
      />
      <ellipse 
        id="path9" 
        style={{ fill: darkColor }} 
        cx="38.344231" 
        cy="71.337105" 
        rx="2.1182907" 
        ry="1.5656931" 
        transform="rotate(-18.538746)" 
      />
      <ellipse 
        id="path10" 
        style={{ fill: primaryColor }} 
        cx="-3.0720294" 
        cy="60.640408" 
        rx="1.5656931" 
        ry="1.7959422" 
        transform="rotate(-59.439034)" 
      />
      <ellipse 
        id="path5" 
        style={{ fill: primaryColor }} 
        cx="23.393297" 
        cy="11.742699" 
        rx="2.0261912" 
        ry="1.611743" 
      />
      <ellipse 
        id="path4" 
        style={{ fill: primaryColor }} 
        cx="24.452444" 
        cy="30.899414" 
        rx="9.5323095" 
        ry="8.9797106" 
      />
      <ellipse 
        id="path4-2" 
        style={{ fill: deepColor }} 
        cx="24.506117" 
        cy="31.499353" 
        rx="7.5061183" 
        ry="7.5521669" 
      />
    </svg>
  )
}

