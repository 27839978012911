/// Asteroid5.tsx
interface Asteroid5Props {
  hue: number
}

export default function Asteroid5({ hue }: Asteroid5Props) {
  const coral = `hsl(${(hue - 74) % 360}, 100%, 79%)` // #ff7b60 -> coral (hue 11)
  const purple = `hsl(${(hue - 29) % 360}, 97%, 56%)` // #6e17ee -> purple (hue 268)
  const magenta = `hsl(${hue}, 96%, 44%)` // #a518af -> magenta (hue 297)
  const deepPurple = `hsl(${(hue - 8) % 360}, 96%, 38%)` // #781183 -> deep purple (hue 284)
  const plum = `hsl(${(hue - 16) % 360}, 88%, 29%)` // #5e0e71 -> plum (hue 281)
  const darkPlum = `hsl(${(hue - 20) % 360}, 90%, 16%)` // #3e0849 -> dark plum (hue 277)

  return (
    <svg className="asteroid5" width="100%" height="100%" viewBox="0 0 336 264" xmlns="http://www.w3.org/2000/svg">

      <path
        style={{ fill: coral }}
        d="m 97.175839,195.15613 c 0,0 -39.953356,-8.06004 -56.976587,-40.69365 0,0 -39.71111079,-21.86166 -13.292241,-54.875785 0,0 8.91306,-76.100961 87.931199,-75.873937 0,0 66.98158,0.627698 102.48367,15.570511 16.56391,9.515926 34.75649,29.829745 39.0866,45.214628 z"
        id="path35" />
      <path
        style={{ fill: purple }}
        d="m 300.16029,142.0048 c 0,0 34.77269,34.22579 9.56523,75.64001 0,0 -13.65513,37.84083 -69.45568,30.9013 l 2.09339,-18.08902 z"
        id="path36" />
      <path
        style={{ fill: magenta }}
        d="m 177.33097,242.60205 c 0,0 -25.32534,-7.37575 -40.97287,-22.59229 0,0 -27.65634,0.13578 -37.808172,-26.45334 0,0 -34.664713,-8.66089 -49.724228,-46.07724 0,0 -29.350136,-21.58952 -5.556091,-40.57978 0,0 3.467273,-21.960114 11.932339,-31.479286 0,0 -5.719114,42.952826 40.775598,41.882776 0,0 -47.278329,-6.73772 -24.043523,-48.809204 1.554815,-3.707411 33.270567,-36.325365 57.795337,2.415874 -1.49937,-6.899735 -6.45495,-30.739782 -38.368412,-26.301948 0,0 11.585612,-10.40153 38.293452,-7.342849 0,0 60.99597,-0.564925 95.86162,14.371571 0,0 37.47249,26.14154 37.52931,57.335926 0,0 28.50271,10.46586 38.88428,36.20575 z"
        id="path37" />
      <path
        style={{ fill: deepPurple }}
        d="m 262.11827,123.63193 c 0,0 85.97618,10.83052 29.95028,101.23889 0,0 7.48854,-19.67455 -14.38259,-27.35003 0,0 16.05277,16.35874 -4.11018,33.37317 0,0 -16.21043,13.1394 -32.19391,-1.505 4.65974,11.53748 21.93479,14.59223 21.93479,14.59223 0,0 -28.87483,11.9107 -87.28948,-1.2962 0,0 -27.27633,-8.16912 -34.89254,-40.3768 0,0 -37.83693,-3.38618 -54.697333,-36.86933 0,0 15.364593,-8.14102 10.369039,-24.11261 0,0 -1.043047,-13.55647 -18.870856,-14.17281 0,0 2.178797,-4.86293 1.265364,-8.71716 23.916606,3.01754 57.755836,-0.54694 50.681136,-48.210033 0,0 42.44197,-17.89358 64.45521,4.71739 2.50231,-10.087299 74.39234,-2.104684 67.78107,48.688293 z"
        id="path38" />
      <path
        id="path40"
        style={{ fill: deepPurple }}
        d="m 50.308265,137.68879 a 11.021267,14.042429 3.777777 0 1 -10.072108,-14.73807 11.021267,14.042429 3.777777 0 1 11.922532,-13.28576 11.021267,14.042429 3.777777 0 1 10.072106,14.73808 11.021267,14.042429 3.777777 0 1 -11.92253,13.28575 z" />
      <path
        id="path41"
        style={{ fill: deepPurple }}
        d="m 68.256143,146.03174 a 10.806158,9.4854056 81.63182 0 1 7.811758,-12.07156 10.806158,9.4854056 81.63182 0 1 10.957076,9.31067 10.806158,9.4854056 81.63182 0 1 -7.81176,12.07154 10.806158,9.4854056 81.63182 0 1 -10.957074,-9.31065 z" />
      <path
        id="path39"
        style={{ fill: deepPurple }}
        d="m 79.18042,64.576682 a 20.52495,22.493098 46.3667 0 1 30.4429,-0.665776 20.52495,22.493098 46.3667 0 1 -2.11681,30.376502 20.52495,22.493098 46.3667 0 1 -30.442893,0.665787 20.52495,22.493098 46.3667 0 1 2.116803,-30.376513 z" />
      <path
        style={{ fill: plum }}
        d="m 250.78328,132.2209 c 0,0 67.33407,8.94469 42.73893,82.60596 0,0 -7.69817,-27.18984 -43.07008,-16.02551 -12.74292,6.97683 -14.75648,17.07666 -3.7195,36.60665 0,0 -86.49271,3.47941 -87.61241,-49.09664 0,0 -36.87035,-4.69533 -32.76816,-53.44706 0,0 21.23253,-76.417388 124.43122,-0.6434 z"
        id="path42" />
      <path
        style={{ fill: purple }}
        d="m 224.98498,128.8397 c 0,0 29.62921,31.65058 6.22307,50.14976 0,0 -15.62125,21.05776 -44.21573,8.80784 0,0 10.60916,-80.58706 37.99266,-58.9576 z"
        id="path44" />
      <path
        style={{ fill: deepPurple }}
        d="m 164.79759,163.05559 c 0,0 -25.33216,8.94012 -25.35792,-18.27755 0,0 -2.51818,-22.87925 31.45086,-9.54659 0,0 20.51138,-30.38448 47.89487,-8.75501 0,0 29.62921,31.65057 6.22309,50.14976 0,0 -15.62125,21.05776 -44.21573,8.80785 0,0 -14.13221,-5.08948 -15.99517,-22.37846 z"
        id="path43" />
      <path
        style={{ fill: darkPlum }}
        d="m 181.29017,154.19098 c 1.54149,-7.50215 7.98998,-13.12708 15.02761,-15.49601 6.95205,-2.38191 15.32607,-1.74734 21.12438,3.05011 4.97159,4.45972 5.98086,11.7526 4.99202,18.05088 -1.04853,8.47846 -7.17812,16.18975 -15.40879,18.69445 -6.62349,2.03242 -12.24585,2.81672 -18.19435,-1.5169 -9.15382,-8.85513 -9.32737,-13.43842 -7.54087,-22.78253 z"
        id="path45" />
      <path
        id="path46"
        style={{ fill: darkPlum }}
        d="m 274.18054,205.84587 a 10.635895,14.726624 60.841025 0 1 -7.67817,16.46334 10.635895,14.726624 60.841025 0 1 -18.0425,-2.1127 10.635895,14.726624 60.841025 0 1 7.67816,-16.46333 10.635895,14.726624 60.841025 0 1 18.04251,2.11269 z" />
    </svg>
  )
}

