import styled from "styled-components/macro";
import { useState, forwardRef } from "react";
import Button from "../TheGame/Button";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { colors } from "../shared-styles";

interface Props {
  save: () => Promise<void>;
  disabled: boolean;
}

const SaveButton = forwardRef<HTMLButtonElement, Props>(({ save, disabled }: Props, ref) => {
  const [isSaving, setIsSaving] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);

  function onSave() {
    setIsSaving(true);
    setSaveComplete(false);
    save()
      .then(() => {
        setSaveComplete(true);
      })
      .finally(() => {
        setIsSaving(false);
      })
      .catch((error) => console.error(error));
  }

  return (
    <StyledSaveButton>
      <div className="save-container">
        <Button
          ref={ref}
          color={colors.asteroid2}
          onClick={onSave}
          buttonText="Save"
          disabled={disabled || isSaving}
          icon={ThumbUpAltIcon}
        ></Button>
        {saveComplete && <div className="save-success">Saved!</div>}
      </div>
    </StyledSaveButton>
  );
});

export default SaveButton;

const StyledSaveButton = styled.div`
  @keyframes save-success {
    0% {
      transform: translateY(0%);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  .save-container {
    display: inline-flex;
    position: relative;

    .save-success {
      position: absolute;
      width: 100%;
      top: 0px;
      right: 0px;
      animation: save-success 1s ease;
      opacity: 0;
    }
  }
`;
