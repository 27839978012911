import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { css, keyframes } from "styled-components"
import StartGame from "./StartGame";
import exampleSet from "../study_set.json";
import {
  getNameFromEmail,
  shuffleArray,
  getPromptImgUrl,
} from "../utils";
import Modal from "../TheGame/Modal";
import starryBackground from "../TheGame/assets/imgs/starry_background.svg";
import restartIcon from "../TheGame/assets/imgs/restart_icon.svg";
import HomeIcon from "@mui/icons-material/Home";
import ModalChild from "../TheGame/ModalChild";
import Button from "../TheGame/Button";
import { btnColors, niceColors } from "../shared-styles";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import RocketIcon from "@mui/icons-material/Rocket";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import StarIcon from "@mui/icons-material/Star";
import {
  get,
  getDatabase,
  // orderByValue,
  // query,
  ref,
  // set,
} from "firebase/database";
// import useIsTeacher from "../useIsTeacher";
import ConfirmGoHomeModal from "../TheGame/ConfirmGoHomeModal";
import MatchGame from "./MatchGame";
import AsteroidFooter from "./AsteroidFooter";
// import AsteroidProgress from "./AsteroidProgress";
import Asteroid1 from "./Asteroid1"
import Asteroid2 from "./Asteroid2"
import Asteroid3 from "./Asteroid3"
import Asteroid4 from "./Asteroid4"
import Asteroid5 from "./Asteroid5"
import Asteroid6 from "./Asteroid6"
import React from "react";
import FloatAround from "../FloatAround";

const db = getDatabase();
const auth = getAuth();

export interface IPromptData {
  e: string;
  c: string;
  i?: string;
}

export const allHues = [286, 13, 262, 45, 129, 315, 340, 182, 222]
export const allAsteroids = [Asteroid1, Asteroid2, Asteroid3, Asteroid4, Asteroid5, Asteroid6]

function TheAsteroids({ demo = false }: { demo?: boolean }) {
  const { setId } = useParams();
  const [user] = useAuthState(auth);
  // const isTeacher = useIsTeacher(auth);
  const [isStarted, setIsStarted] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [leaderboard, setLeaderboard] = useState<[string, number][]>([]);
  // const [userRank, setUserRank] = useState(0);
  const [allPrompts, setAllPrompts] = useState<IPromptData[]>([]);
  // const [highscore, setHighscore] = useState<number>(0);
  const [showConfirmGoHome, setShowConfirmGoHome] = useState(false);
  const [tiles, setTiles] = useState<IPromptData[]>([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isWrong, setIsWrong] = useState(false)

  useEffect(() => {
    if (isWrong) {
      const timeout = setTimeout(() => setIsWrong(false), 800)
      return () => clearTimeout(timeout)
    }
  }, [isWrong])

  useEffect(() => {
    if (demo) {
      const getSrc = (img: string) => {
        try {
          return require(`../TheGame/assets/vocab/${img}`);
        } catch (ex) {
          return null;
        }
      };
      setAllPrompts(
        exampleSet.map((obj) => ({ ...obj, i: getSrc(`${obj.e}.jpg`) }))
      );
    } else {
      const dbRef = ref(db, `setItems/${setId}`);
      get(dbRef).then((snapshot) => {
        const val = snapshot.val();
        const set = Object.entries(val ?? {}) as [string, IPromptData][];
        if (set.length === 0) {
          throw new Error("set data not found");
        }
        setAllPrompts(
          set.map(([id, data]) => ({
            ...data,
            i: data.i ? getPromptImgUrl(id) : undefined,
          }))
        );
      });
    }
  }, [demo, setId]);

  useEffect(() => {
    if (!demo) {
      const dbRef = ref(db, `highscores/${setId}/${user!.uid}`);
      get(dbRef).then(async (snapshot) => {
        // setHighscore(snapshot.val());
      });
    }
  }, [demo, setId, user]);

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setElapsedTime(t => t + 100);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isRunning]);

  const start = () => {
    // Select 6 random prompts and create matching tiles
    const gamePrompts = shuffleArray(allPrompts).slice(0, 6);
    setTiles(gamePrompts);
    setIsStarted(true);
    setIsRunning(true);
    setElapsedTime(0);
  };

  const restart = () => {
    setIsStarted(false);
    setIsRunning(false);
    setIsGameOver(false);
    setTiles([]);
    setElapsedTime(0);
    setLeaderboard([]);
    setAsteroidsFound([]);
  };

  const navigate = useNavigate();
  const goHome = () => {
    setShowConfirmGoHome(true);
  };
  const confirmGoHome = () => navigate("home");
  // const pause = () => setIsRunning(false);

  // const handleTileClick = (tile: IMatchingTile) => {
  //   if (!isRunning || tile.isMatched || tile.isRevealed || selectedTiles.length >= 2) return;

  //   const newTiles = [...tiles];
  //   const clickedTile = newTiles[tile.index];
  //   clickedTile.isRevealed = true;
  //   setTiles(newTiles);

  //   const newSelected = [...selectedTiles, clickedTile];
  //   setSelectedTiles(newSelected);

  //   if (newSelected.length === 2) {
  //     const [first, second] = newSelected;
  //     if (first.data === second.data) {
  //       // Match found
  //       setTimeout(() => {
  //         const matchedTiles = [...tiles];
  //         matchedTiles[first.index].isMatched = true;
  //         matchedTiles[second.index].isMatched = true;
  //         setTiles(matchedTiles);
  //         setSelectedTiles([]);

  //         // Check if game is complete
  //         if (matchedTiles.every(t => t.isMatched)) {
  //           setIsRunning(false);
  //           setIsGameOver(true);

  //           if (!demo) {
  //             const time = elapsedTime;
  //             const isHighscore = !highscore || time < highscore;

  //             if (isHighscore && !isTeacher) {
  //               setHighscore(time);
  //               const dbRef = ref(db, `highscores/${setId}/${user!.uid}`);
  //               set(dbRef, time);

  //               // Update weekly/monthly highscores
  //               set(ref(db, `weeklyHighscores/${setId}/${user!.uid}`), time);
  //               set(ref(db, `monthlyHighscores/${setId}/${user!.uid}`), time);
  //             }

  //             // Get leaderboard
  //             const topScoresRef = query(
  //               ref(db, `highscores/${setId}`),
  //               orderByValue()
  //             );

  //             get(topScoresRef).then((snapshot) => {
  //               const val = (snapshot.val() as { [key: string]: number }) ?? {};
  //               const scoresArray = Object.entries(val).sort(
  //                 ([_, x], [__, y]) => x - y
  //               );
  //               const rank =
  //                 scoresArray.findIndex(([u, s]) => u === user?.uid || s > time) + 1;
  //               setUserRank(rank || (scoresArray.length + 1));
  //               setLeaderboard(scoresArray);
  //             });
  //           }
  //         }
  //       }, 500);
  //     } else {
  //       // No match
  //       setTimeout(() => {
  //         const resetTiles = [...tiles];
  //         resetTiles[first.index].isRevealed = false;
  //         resetTiles[hue={second.index].isRevealed = false;
  //         setTiles(resetTiles);
  //         setSelectedTiles([]);
  //       }, 1000);
  //     }
  //   }
  // };


  const hues = useMemo(() => (isStarted ? shuffleArray([...allHues]) : []), [isStarted]);
  const asteroids = useMemo(() => (isStarted ? shuffleArray([...Array(allAsteroids.length).keys()]) : []), [isStarted]);
  const [asteroidsFound, setAsteroidsFound] = useState<number[]>(([]));
  // useEffect(() => { console.log(asteroidsFound) }, [asteroidsFound])
  // useEffect(() => { console.log(hues) }, [hues])
  // useEffect(() => { console.log(asteroids) }, [asteroids])

  const FoundAsteroids = useCallback(() => {
    return (
      <div className="found-asteroids">
        {
          asteroidsFound.map((i, index) => (<div key={index} className="found-asteroid" data-index={index}>
            <FloatAround index={i} amplitude={8}>
              {React.createElement(allAsteroids[asteroids[i]], { hue: hues[i] })}
            </FloatAround>
          </div>
          ))
        }
      </div>
    );
  }, [asteroids, asteroidsFound, hues])

  return (
    <StyledTheAsteroids isWrong={isWrong}>
      {!isStarted && (
        <StartGame
          onStart={start}
          canStart={allPrompts.length > 0}
        />
      )}
      <div className="top">
        {isStarted && (
          <button onClick={restart}>
            <img src={restartIcon} alt="restart" />
          </button>
        )}
        <span className="spacer"></span>
        {isStarted && (
          <>
            <button onClick={goHome} title="home">
              <HomeIcon className="home-icon"></HomeIcon>
            </button>
          </>
        )}
      </div>

      {/* {isStarted && (
        <div>
          <AsteroidProgress
            totalAsteroids={6}
            discoveredHues={huesFound}
          />
        </div>
      )} */}
      <div className="main">

        {isStarted && (
          <>
            <MatchGame hues={hues}
              asteroids={asteroids}
              prompts={tiles}
              onWrong={() => setIsWrong(true)}
              onComplete={() => {
                setIsRunning(false);

                setTimeout(() => {
                  setIsGameOver(true);
                }, 1000);

                // if (!demo) {
                //   const time = elapsedTime;
                //   const isHighscore = !highscore || time < highscore;

                //   if (isHighscore && !isTeacher) {
                //     setHighscore(time);
                //     const dbRef = ref(db, `highscores/${setId}/${user!.uid}`);
                //     set(dbRef, time);

                //     // Update weekly/monthly highscores
                //     set(ref(db, `weeklyHighscores/${setId}/${user!.uid}`), time);
                //     set(ref(db, `monthlyHighscores/${setId}/${user!.uid}`), time);
                //   }

                //   // Get leaderboard
                //   const topScoresRef = query(
                //     ref(db, `highscores/${setId}`),
                //     orderByValue()
                //   );

                //   get(topScoresRef).then((snapshot) => {
                //     const val = (snapshot.val() as { [key: string]: number }) ?? {};
                //     const scoresArray = Object.entries(val).sort(
                //       ([_, x], [__, y]) => x - y
                //     );
                //     const rank =
                //       scoresArray.findIndex(([u, s]) => u === user?.uid || s > time) + 1;
                //     setUserRank(rank || (scoresArray.length + 1));
                //     setLeaderboard(scoresArray);
                //   });
                // }
              }}
              onAsteroidFound={(i: number) => setAsteroidsFound((ast) => ([...ast, i]))}>
              <div className="timer" data-done={!isRunning}>Time: {(elapsedTime / 1000).toFixed(1)}s</div>
              {/* <FoundAsteroids /> */}
            </MatchGame>

          </>
        )}

        {isStarted && (
          <AsteroidFooter
            totalAsteroids={tiles.length}
            discoveredHues={asteroidsFound.map(i => hues[i])}
          />
        )}
      </div>


      {/* {isStarted && (
        <div className="game-grid">
          {tiles.map((tile, i) => (
            <div
              key={i}
              className={`tile ${tile.isRevealed ? 'revealed' : ''} ${tile.isMatched ? 'matched' : ''}`}
              onClick={() => handleTileClick(tile)}
            >
              <img
                src={tile.isRevealed || tile.isMatched ? asteroidExplosion : greenAsteroid}
                alt="asteroid"
                className={`asteroid ${tile.isRevealed ? 'revealed' : ''} ${tile.isMatched ? 'matched' : ''}`}
              />
              {(tile.isRevealed || tile.isMatched) && (
                <div className="content">
                  {i < 6 ? tile.data.c : tile.data.e}
                </div>
              )}
            </div>
          ))}
        </div>
      )} */}

      {
        isGameOver && (
          <Modal>
            <div className="game-over">
              <h1>All Asteroids Found!</h1>
              <div className="game-over-summary">
                <ModalChild>
                  <FoundAsteroids />
                  <div className="completion-time">
                    Time: {(elapsedTime / 1000).toFixed(1)}s
                  </div>
                  {leaderboard.length > 0 && (
                    <>
                      <div className="highscores-title">
                        <RocketIcon />
                        Fastest Times
                        <RocketIcon />
                      </div>
                      <div className="highscores">
                        <div className="highscore-col">
                          {leaderboard.map(([name, value], index) =>
                            <div key={index} className="highscore">
                              {!!user && name === getNameFromEmail(user) && (
                                <StarIcon className="star" />
                              )}
                              <div className="highscore-rank">{index + 1}.</div>
                              <div className="highscore-name">{name}</div>
                              <div className="highscore-score">{(value / 1000).toFixed(1)}s</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </ModalChild>
              </div>
              <div className="game-over-options">
                <Button
                  icon={RocketLaunchIcon}
                  width={200}
                  className="game-over-option"
                  onClick={restart}
                  buttonText="PLAY AGAIN"
                  color={btnColors.launch}
                />
                <Button
                  width={200}
                  className="game-over-option"
                  onClick={confirmGoHome}
                  buttonText="HOME"
                  color={btnColors.home}
                />
              </div>
            </div>
          </Modal >
        )
      }
      {
        showConfirmGoHome && (
          <ConfirmGoHomeModal
            cancel={() => {
              setShowConfirmGoHome(false);
            }}
            confirm={confirmGoHome}
          />
        )
      }
    </StyledTheAsteroids >
  );
}

export default TheAsteroids;

const StyledTheAsteroids = styled.div<{ isWrong: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #1e163d;

  .top {
    background-color: #1e163d;
    display: flex;
    align-items: center;
    justify-content: end;
    z-index: 1;
    height: 3.5rem;
    color: white;
    padding: 5px;

    .spacer {
      flex: 1;
    }

    .notification {
      font-size: min(5vw, 2rem);
      position: absolute;
      width: 99%;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: min(5vw, 2rem);
        height: min(5vw, 2rem);
      }

      .warning {
        color: orange;

        .warning-text {
          padding-left: min(2vw, 20px);
          padding-right: min(2vw, 20px);
        }
      }

      .info {
        color: cyan;
      }
    }

    button {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 2.2rem;
        height: 2.2rem;
      }

      .home-icon {
        width: 3rem;
        height: 3rem;
        color: #846dc6;
      }
    }

    .continue img {
      transform: rotate(180deg);
    }
  }

  @keyframes levelling {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .match-game {
    animation: ${props => props.isWrong ? css`${flashAnimation} 0.8s linear forwards` : 'none'};
  }

  .main {
    z-index: 0;
    background-image: url(${starryBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .levelling-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${niceColors.darkDarkPurple};
      animation: levelling 0.5s forwards, levelling 0.5s reverse;
      animation-delay: 0s, 3.5s;
    }
    @keyframes fly-in {
      0% {
        transform: scale(0);
      }
      80% {
        transform: scale(1.02);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes fly-beyond {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(0.99) translateY(1%);
      }
      100% {
        transform: scale(30) translateY(-100%);
      }
    }
    .level-hero {
      transform: scale(0);
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fly-in 0.5s ease-out forwards, fly-beyond 1s ease-in forwards;
      animation-delay: 0.5s, 3s;

      .level-hero-text {
        font-size: 15vw;
        font-weight: bold;
      }
    }
  }

  .ground {
    background-color: brown;
    height: 5px;
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: 0;
    backdrop-filter: blur(1px);

    .flex1 {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 500px) {
      flex-direction: column-reverse;
      .game-progress-container {
        padding-left: 0;
      }
    }

    padding: 10px 30px;

    form {
      flex: 2;
      display: flex;
      justify-content: center;
      margin-block: 5px;
    }

    .game-progress-container {
      position: relative;
      padding-left: 20px;
    }
  }



  .found-asteroids {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      
      .found-asteroid {
        animation: scaleIn 0.5s ease-out forwards;
        transform: scale(0);

        svg {
          max-height: 5vh;
          aspect-ratio: 1;
        }
      }

      .found-asteroid[data-index="0"] { animation-delay: 0s; }
      .found-asteroid[data-index="1"] { animation-delay: 0.1s; }
      .found-asteroid[data-index="2"] { animation-delay: 0.2s; }
      .found-asteroid[data-index="3"] { animation-delay: 0.3s; }
      .found-asteroid[data-index="4"] { animation-delay: 0.4s; }
      .found-asteroid[data-index="5"] { animation-delay: 0.5s; }

      @keyframes scaleIn {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1); 
        }
      }
    }

  .game-over {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 3rem;
      margin-block: 0;
    }

    .leaderboard {
      display: flex;

      .col {
        flex: 1;
      }
    }

    .game-over-summary {
      margin-block: 30px;

      .game-progress {
        justify-content: center;
      }

      .highscores-title {
        font-weight: bold;
        margin-block: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .highscores {
        display: flex;
        justify-content: flex-end;

        .highscore-col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .highscore-divider {
          width: 100%;
          height: 1px;
          background-color: rgb(50, 50, 50);
          border: none;
        }
      }

      .highscore {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: 8px;
        margin-right: 8px;
        font-size: 0.9rem;

        .highscore-rank {
          width: 1.2rem;
          text-align: start;
        }

        .highscore-name {
          margin-left: 5px;
          margin-right: 20px;
          width: 130px;
          text-align: start;
          text-transform: none;
        }

        .highscore-score {
          color: yellow;
          width: 70px;
          text-align: end;
        }

        .star {
          color: yellow;
          font-size: 1rem;
          position: absolute;
          left: 0;
          transform: translateX(-120%);
        }
      }
    }

    .leaderboard-container {
      margin-top: 10px;
    }

    .game-over-message {
      font-size: 2rem;
    }

    .game-over-options {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .game-over-options > div:not(:first-child) > div {
      margin-top: 10px;
    }
  }

  .answer-input {
    flex: 1;
    max-width: 500px;
    background: ${niceColors.darkDarkPurple};
  }
  
  .timer {
    color: white;
    font-size: min(4vw, 3.5rem);
    margin-block: 20px;
    text-transform: none;
    font-family: monospace; /* Add monospace font */
    text-align: center;

    opacity: 0;
    @keyframes appear {
        0% {
          opacity: 0;
          transform: scale(0);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
      animation: appear 0.5s ease 0.2s forwards;

    &[data-done="true"] {
      opacity: 1;
      color: gold;
      @keyframes swell {
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
      animation: swell 0.4s ease;
    }
  }

  .completion-time {
    font-size: 2rem;
    color: gold;
    text-align: center;
    text-transform: none;
  }

  @keyframes pulse {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1.05);
    }
  }
`;

// Define keyframes
const flashAnimation = keyframes`
  from {
    background-color: rgba(255, 127, 127, 0.5);
  }
  to {
    background-color: rgba(255, 0, 0, 0);
  }
`;
