import { useMemo } from "react";
import Modal from "../TheGame/Modal";
import styled from "styled-components/macro";
import Button from "../TheGame/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { btnColors } from "../shared-styles";
import MatchGame from "./MatchGame";
import { shuffleArray } from '../utils';
import { allAsteroids, allHues } from "./TheAsteroids";

interface IProps {
  onStart: () => void;
  canStart: boolean;
}

function StartGame({ onStart, canStart }: IProps) {
  const hue = useMemo(() => shuffleArray([...allHues])[0], []);
  const asteroid = useMemo(() => Math.floor(Math.random() * allAsteroids.length), []);

  return (
    <Modal>
      <StyledStartGame>
        <div className="main-container">
          <div className="text-container">
            <h1>MATCHTEROIDS</h1>
            <h3>MATCH THE ASTEROIDS AS FAST AS YOU CAN!</h3>
          </div>
          <div className="asteroid-rows">
            <MatchGame hues={[hue]} asteroids={[asteroid]} prompts={[{
              e: "",
              c: "",
            }]
            }
              onComplete={() => { }}
              onAsteroidFound={function (_): void { }} onWrong={() => { }} />
          </div>
          <Button
            disabled={!canStart}
            width={250}
            icon={RocketLaunchIcon}
            color={btnColors.launch}
            onClick={onStart}
            buttonText="START MATCHING!"
          />
        </div>
      </StyledStartGame>
    </Modal>
  );
}

export default StartGame;

const StyledStartGame = styled.div`
  width: 100%;

  .cell-item {
    width: 150px !important;
    height: 160px;
  }

  .main-container {
    width: 100%;
    padding: 10px 20px 25px 20px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .asteroid {
    width: 25px;
    margin: 0 5px;
  }

  h1 {
    font-size: 3rem;
    text-align: left;
    margin: 0;
  }

  h3 {
    margin: 0;
    text-align: left;
  }

  .orbiting-asteroids-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .explainer-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .asteroids-explainer {
    height: 300px;
    aspect-ratio: 1;
  }

  .options-container {
    display: flex;
    justify-content: center;

    .modal-child {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;

    .option {
      margin-top: 10px;
    }

    .option-powerups {
      margin-top: 20px;
      align-self: flex-end;
    }
  }

  .asteroid-rows {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .asteroid-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
