import styled from "styled-components/macro";
import * as motion from "motion/react-client";
import { useMemo } from "react";

interface FloatAnimation {
  x: number;
  y: number;
  duration: number;
  delay: number;
}

interface Props {
  className?: string;
  children: React.ReactNode;
  amplitude?: number;
  index?: number;
  useSlideDown?: boolean;
}

const FloatAround = ({
  className,
  children,
  amplitude = 15,
  index = 0,
}: Props) => {
  const floatAnimation: FloatAnimation = useMemo(() => ({
    x: Math.sin(index * 0.5) * amplitude, // Different amplitude based on index
    y: Math.cos(index * 0.7) * amplitude, // Different phase
    duration: 2 + Math.random() * 2, // Random duration between 2-4s
    delay: Math.random() * 0.5, // Small random delay
  }), [index, amplitude]);

  return (
    <StyledFloatAround
      className={className}
    >
      <motion.div
        animate={{
          x: floatAnimation.x,
          y: floatAnimation.y,
        }}
        transition={{
          x: {
            duration: floatAnimation.duration,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
            delay: floatAnimation.delay,
          },
          y: {
            duration: floatAnimation.duration * 1.2,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
            delay: floatAnimation.delay,
          },
        }}
      >
        {children}
      </motion.div>
    </StyledFloatAround>
  );
};

export default FloatAround;

const StyledFloatAround = styled.div`
  display: block;
  will-change: transform;
  transform: translateZ(0);
`;