import styled from "styled-components/macro";
import { SmallButton } from "./TheGame/Button";
import { ref, get, getDatabase } from "firebase/database";
import { useEffect, useState } from "react";
import ModalChild from "./TheGame/ModalChild";
import Modal from "./TheGame/Modal";
import { btnColors, colors, niceColors } from "./shared-styles";
import { ItemData } from "./Admin/Set/EditSet";
import { PresentationSetItem } from "./PresentationSetItem";
import ListIcon from '@mui/icons-material/List';
import OrbitingAsteroids from "./TheGame/OrbitingAsteroids";

const db = getDatabase();

interface Props {
  dismiss: VoidFunction;
  setId: string;
}

function PresentationModal({ setId, dismiss }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<ItemData[]>([]);

  useEffect(() => {
    const dbRef = ref(db, `setItems/${setId}`);
    get(dbRef).then(
      (snapshot) => {
        const val = (snapshot.val() ?? {}) as { [id: string]: ItemData };
        const newItems = Object.entries(val)
          .map(([id, data]) => ({
            ...data,
            id,
          }))
          .sort((a, b) => (a.t === b.t ? a.e.localeCompare(b.e) : (a.t ?? 0) - (b.t ?? 0)));
        setItems(newItems);
        setIsLoading(false);
      },
      (error) => console.error(error)
    );
  }, [setId]);

  return (
    <StyledPresentationModal>
      {(
        <Modal dismiss={dismiss}>
          <h1>
            <ListIcon />
            <span className="mission-heading">Mission Briefing</span>
            <ListIcon />
          </h1>
          <div className="mission-subheading"> items ({items.length})</div>
          <ModalChild>
            {isLoading ? <OrbitingAsteroids />
              : <div className="set-items">
                {items.map((item, index) => (
                  <div className="item-wrapper" key={item.id}>
                    <div className="item-wrapper-label">
                      {/* <GamepadIcon /> */}
                      <div className="item-number">{index + 1}.</div>
                    </div>
                    <PresentationSetItem {...item} />
                  </div>
                ))}
              </div>
            }
          </ModalChild>
          <SmallButton color={btnColors.presentation} className="close" buttonText="Close" onClick={dismiss} />
        </Modal>
      )}
    </StyledPresentationModal>
  );
}

export default PresentationModal;

const StyledPresentationModal = styled.div`
  .close {
    margin-top: 20px;
    .button-top{
      color: black;
    }
  }

  .mission-heading {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mission-subheading {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .set-items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));

    .item-wrapper {
      display: flex;
      /* flex: 1 1 375px; */
      height: 120px;
      margin-block: 5px;

      .item-wrapper-label {
        width: 50px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .remove-item-btn {
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }
  }

  .modal-child {
    height: 70vh;
    overflow: auto;
    width: 100%;
  }

  /* Define scrollbar styles */
  .modal-child::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  /* Track */
  .modal-child::-webkit-scrollbar-track {
    background: #e6d3f0; /* Color of the scrollbar track */
    border-radius: 8px; /* Rounded corners for the scrollbar track */
  }

  /* Handle */
  .modal-child::-webkit-scrollbar-thumb {
    background: #a989c5; /* Color of the scrollbar handle */
    border-radius: 8px; /* Rounded corners for the scrollbar track */
  }

  /* Handle on hover */
  .modal-child::-webkit-scrollbar-thumb:hover {
    background: #7a5596; /* Color of the scrollbar handle on hover */
  }

  .set-items textarea {
    font-size: 1rem;
    padding-block: 0.1rem;

    &#item-chinese {
      background-color: ${colors.asteroid};
      color: ${niceColors.darkPurple};
    }

    &#item-english {
      background-color: ${niceColors.darkPurple};
      color: white;
    }
  }

  .app-content-container {
    width: 100%;

    .app-content {
      justify-content: center;
      align-items: center;

      .edit-header {
        width: min(900px, 100%);
      }
    }
  }
`;
