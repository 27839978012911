/// Asteroid6.tsx
interface Asteroid6Props {
  hue: number
}

export default function Asteroid6({ hue }: Asteroid6Props) {
  const yellow = `hsl(${(hue + 220) % 360}, 94%, 50%)`     // #f8b500
  const purple = `hsl(${(hue + 87) % 360}, 47%, 44%)`      // #7942ac
  const teal = `hsl(${hue}, 91%, 52%)`                     // #09a4aa
  const darkTeal = `hsl(${(hue + 9) % 360}, 98%, 34%)`     // #016574
  const navy = `hsl(${(hue + 19) % 360}, 95%, 18%)`        // #024455

  return (
    <svg className="asteroid6" width="100%" height="100%" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ fill: purple }}
        d="m 131.54311,236.54675 c 0,0 16.12963,13.70805 30.12313,14.77991 18.65374,1.16661 53.66723,6.02943 78.23494,0.29876 0,0 37.22516,-4.50303 46.33577,-55.27516 0,0 5.63095,-36.9467 -23.93356,-57.68802 z"
        id="path2" />
      <path
        style={{ fill: yellow }}
        d="m 58.027864,190.29823 c 0,0 -43.837229,-24.45359 -32.982236,-81.65655 0.643256,-5.06538 23.734583,-65.897534 92.112762,-51.598612 0,0 13.23558,-20.911737 41.59302,0.909206 0,0 20.39355,-1.002556 37.43268,14.149506 z"
        id="path3" />
      <path
        style={{ fill: teal }}
        d="m 131.13749,236.50322 c 0,0 -9.60787,-5.96418 -14.71939,-15.63008 0,0 -8.62774,2.79531 -20.840277,-13.53726 0,0 -85.00636,-14.37463 -56.581582,-102.50361 C 56.708804,66.003182 85.54525,63.843704 85.54525,63.843704 c 0,0 19.60386,-5.525495 29.26446,-2.699182 0,0 -0.61941,15.62193 20.61147,17.153248 0,0 -18.57169,-9.193398 -12.89849,-16.344206 0,0 -1.67974,-14.967921 23.61647,-13.485096 0,0 11.07467,2.245979 13.33911,14.71104 0,0 20.1596,-1.643962 36.42139,8.998328 0,0 26.24452,13.998198 32.81628,35.905124 l 4.76099,13.49751 c 0,0 28.47391,5.99019 36.85416,31.44469 0,0 20.34026,37.10433 -4.91313,66.97076 0,0 -3.28139,-8.33185 -10.11461,-12.05867 0,0 11.33976,18.75761 -9.44183,31.75515 0,0 -12.55781,7.34197 -22.26925,2.93198 0,0 -0.92867,4.74117 -5.77802,4.12534 0,0 -25.5638,0.7313 -56.042,-1.463 0,0 -15.48503,-0.19977 -30.63476,-8.7835 z"
        id="path4" />
      <path
        style={{ fill: darkTeal }}
        d="m 160.80416,244.34646 c -6.19465,-1.35604 -27.84876,-8.18503 -36.4515,-36.23987 0,0 -29.320894,3.09019 -52.073226,-30.72065 0,0 13.09476,-4.7222 8.893803,-20.01553 0,0 -2.068663,-11.92227 -16.918866,-13.80812 0,0 -1.341602,-12.45828 6.784302,-24.82568 0,0 13.602733,-21.995675 31.250047,-28.824926 12.60061,-4.749044 19.46777,-4.515336 19.46777,-4.515336 0,0 21.11463,-2.411157 59.37685,2.117971 0,0 25.66022,0.990797 40.205,24.111831 0,0 4.32647,2.34646 7.33973,-3.92236 0,0 5.55413,9.72889 6.42899,24.30608 0,0 18.89039,0.19797 37.20001,23.16818 0,0 17.89144,29.1447 -6.48528,64.54048 0,0 -2.11507,-5.57762 -11.63216,-12.49268 0,0 13.90802,10.16128 -2.67979,28.30002 0,0 -12.16875,13.22683 -27.5067,7.49898 0,0 -0.97073,3.53993 -3.2171,3.73985 0,0 -15.57699,1.71965 -59.98188,-2.41824 z"
        id="path5" />
      <path
        id="path6"
        style={{ fill: darkTeal }}
        d="m 74.204278,158.38456 a 11.406458,9.3935537 70.076159 0 1 -4.944328,13.9248 11.406458,9.3935537 70.076159 0 1 -12.718304,-7.5227 11.406458,9.3935537 70.076159 0 1 4.944327,-13.92479 11.406458,9.3935537 70.076159 0 1 12.718305,7.52269 z" />
      <path
        id="ellipse6"
        style={{ fill: darkTeal }}
        d="m 139.5193,52.037022 a 8.7896576,12.351384 84.667748 0 1 13.11477,7.603792 8.7896576,12.351384 84.667748 0 1 -11.48111,9.899449 8.7896576,12.351384 84.667748 0 1 -13.11476,-7.603794 8.7896576,12.351384 84.667748 0 1 11.4811,-9.899447 z" />
      <path
        id="ellipse7"
        style={{ fill: navy }}
        d="m 230.20272,214.34983 a 11.075115,13.214736 53.5872 0 1 17.20888,1.06857 11.075115,13.214736 53.5872 0 1 -4.06053,16.75707 11.075115,13.214736 53.5872 0 1 -17.20888,-1.06857 11.075115,13.214736 53.5872 0 1 4.06053,-16.75707 z" />
      <path
        id="ellipse8"
        style={{ fill: navy }}
        d="m 208.13352,145.80648 a 12.769037,14.254956 53.5872 0 1 19.05152,1.8143 12.769037,14.254956 53.5872 0 1 -3.89215,18.73775 12.769037,14.254956 53.5872 0 1 -19.05152,-1.8143 12.769037,14.254956 53.5872 0 1 3.89215,-18.73775 z" />
      <path
        style={{ fill: purple }}
        d="m 227.00238,135.89148 c 0,0 21.05214,6.9769 11.49546,29.56567 0,0 -11.70276,16.38638 -27.63956,9.96323 16.34794,-0.20333 21.31944,-13.86135 21.31944,-13.86135 0,0 6.63188,-13.71177 -5.17534,-25.66755 z"
        id="path10" />
      <path
        style={{ fill: navy }}
        d="m 261.49728,213.57348 c 0,0 -17.80137,-18.58392 -34.83572,-6.21278 0,0 -17.2726,12.64967 -9.80042,28.85213 0,0 -36.96521,-0.97615 -46.89794,-3.4662 0,0 -24.21954,-7.27114 -31.39342,-36.44171 0,0 -36.34969,-8.03239 -32.9995,-46.68484 0,0 2.81738,-28.3213 36.03725,-33.87215 0,0 24.0492,-1.77079 50.14968,3.43172 0,0 10.7707,2.02706 15.97482,12.72713 0,0 -14.11645,4.26081 -14.80143,18.85799 0,0 -1.8099,18.49998 17.69231,24.45284 0,0 14.87287,6.77344 26.27567,-8.92003 0,0 6.67415,-7.48347 1.42887,-21.63503 26.31765,12.93177 27.5431,29.23998 27.5431,29.23998 0,0 8.20129,18.76206 -4.37327,39.67095 z"
        id="path9" />
      <path
        id="path8"
        style={{ fill: darkTeal }}
        d="m 139.64374,148.85834 a 9.930892,10.416533 47.042874 0 1 -0.85607,14.36642 9.930892,10.416533 47.042874 0 1 -14.3909,-0.1697 9.930892,10.416533 47.042874 0 1 0.85606,-14.36643 9.930892,10.416533 47.042874 0 1 14.39091,0.16971 z" />
      <path
        id="ellipse9"
        style={{ fill: teal }}
        d="m 209.8267,103.48395 a 8.8926821,8.8926821 0 0 1 -0.44831,12.56816 8.8926821,8.8926821 0 0 1 -12.56816,-0.44831 8.8926821,8.8926821 0 0 1 0.44831,-12.56816 8.8926821,8.8926821 0 0 1 12.56816,0.44831 z" />

    </svg>
  )
}

