import styled from "styled-components/macro";
import OrbitingAsteroids from "./TheGame/OrbitingAsteroids";

interface Props {
  children?: React.ReactNode;
  isLoading?: boolean;
}

const Loading = ({ isLoading = true, children }: Props) => {
  return (
    <StyledLoading className={`${isLoading ? "is-loading" : ""}`}>
      <div className="loading-msg">
        <OrbitingAsteroids />
        <span className="loading-text">Loading...</span>
      </div>
      {children && <div className="content">{children}</div>}
    </StyledLoading>
  );
};

export default Loading;

const StyledLoading = styled.div`
  display: flex;
  flex-direction: column;

  .loading-msg {
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;

    .loading-text {
      margin-top: 20px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  &.is-loading .content {
    display: none;
  }

  &:not(.is-loading) .loading-msg {
    display: none;
  }
`;
