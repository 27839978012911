import styled from "styled-components/macro";
import RenameItemButton from "./RenameItemButton";
import DeleteItemButton from "./DeleteItemButton";
import DuplicateSetButton from "./DuplicateSetButton";
import CopySetUrlButton from "./CopySetUrlButton";

interface Props {
  id: string;
  itemType: string;
  rename: (from: string, to: string) => Promise<void>;
  deleteItem: (id: string) => Promise<void>;
  children?: React.ReactNode;
}

const ItemActions = ({ id, itemType, rename, deleteItem, children }: Props) => {
  return (
    <StyledAdmin>
      <div className="item-actions">
        {children}
        <RenameItemButton
          itemType={itemType}
          existingId={id!}
          submit={(newId) => rename(id, newId)}
        ></RenameItemButton>
        {itemType === "set" && (
          <>
            <DuplicateSetButton id={id}></DuplicateSetButton>
            <CopySetUrlButton id={id} />
          </>
        )}
        <DeleteItemButton
          itemType={itemType}
          id={id}
          submit={() => deleteItem(id)}
        />
      </div>
    </StyledAdmin>
  );
};

export default ItemActions;

const StyledAdmin = styled.div`
  .item-actions {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
`;
