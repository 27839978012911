import styled from "styled-components/macro";
import Modal from "./Modal";
import Button from "./Button";
import { btnColors } from "../shared-styles";
import ModalChild from "./ModalChild";
import HomeIcon from '@mui/icons-material/Home';
import ReportIcon from "@mui/icons-material/Report";

interface ConfirmGoHomeModalProps {
    cancel: () => void;
    confirm: () => void;
}

function ConfirmGoHomeModal({ cancel, confirm }: ConfirmGoHomeModalProps) {
    return (
        <StyledConfirmGoHomeModal>
            <Modal dismiss={cancel}>
                <h1>Back To Home?</h1>
                <div className="spacer"></div>
                <ModalChild className="child">
                    <ReportIcon />
                    <div className="confirmation-message">
                        Your progress will be lost!
                    </div>
                </ModalChild>
                <div className="spacer"></div>
                <Button
                    icon={HomeIcon}
                    width={200}
                    buttonText="HOME"
                    color={btnColors.home}
                    className="confirm-go-home"
                    onClick={confirm}
                />
                <button className="cancel" onClick={cancel}>
                    Cancel
                </button>
            </Modal>
        </StyledConfirmGoHomeModal>
    );
}

export default ConfirmGoHomeModal;

const StyledConfirmGoHomeModal = styled.div`
  .child {
    display: flex;
        align-items: center;
  }

  .confirmation-message {
    margin-left: 10px;
    text-align: center;
  }

  .confirm-go-home {
    margin-top: 30px;
  }

  button.cancel {
    margin-top: 20px;
  }

  .spacer {
    flex: 1;
  }
`;
