// Asteroid3.tsx
interface Asteroid3Props {
  hue: number
}

export default function Asteroid3({ hue }: Asteroid3Props) {
  // light scheme
  const lightPurple = `hsl(${(hue + 22) % 360}, 97%, 85%)`     // #6e4db8
  const brightPurple = `hsl(${(hue - 22) % 360}, 96%, 55%)`     // #6e4db8
  const mediumPurple = `hsl(${hue}, 89%, 50%)`   // #aaaded
  const darkPurple = `hsl(${(hue - 12) % 360}, 100%, 32%)`        // #3e248d
  const deepPurple = `hsl(${(hue - 12) % 360}, 80%, 25%)`        // #260c73
  // dark scheme
  // const lightPurple = `hsl(${hue}, 97%, 77%)`      // #a08cfe
  // const brightPurple = `hsl(${hue}, 86%, 55%)`     // #6e26f1
  // const mediumPurple = `hsl(${hue}, 83%, 28%)`     // #470c83
  // const darkPurple = `hsl(${hue}, 78%, 22%)`       // #280c64
  // const deepPurple = `hsl(${hue}, 76%, 13%)`       // #16053e

  return (
    <svg className="asteroid3" width="100%" height="100%" viewBox="0 0 105 93" xmlns="http://www.w3.org/2000/svg"    >
      <defs id="defs1" />
      <path
        style={{ fill: lightPurple }}
        d="M 19.071273,26.496256 C 32.491486,15.394135 54.705213,3.6032782 62.074628,3.2538971 82.21071,0.20481204 108.0971,30.576826 90.336301,55.876881 86.405557,60.954684 65.29059,74.338633 56.611374,77.258829 c -5.518581,1.899623 -19.63451,1.47639 -29.835024,-1.711271 0,0 -15.938303,-0.318766 -14.344473,-14.344473 0,0 -8.5293384,-23.959399 6.639396,-34.706829 z"
        id="path1"
      />
      <path
        style={{ fill: brightPurple }}
        d="M 62.844423,8.6424653 C 87.34268,2.7707969 108.0971,30.576826 90.336301,55.876881 86.405557,60.954684 65.29059,74.338633 56.611374,77.258829 53.408123,78.286798 48.23411,77.482663 42.874306,75.632543 72.433,60.747716 100.92916,25.13938 62.844423,8.6424653 Z"
        id="path14"
      />
      <path
        id="path2"
        style={{ fill: mediumPurple }}
        d="m 58.378906,9.3186857 c 0,0 -31.192104,12.6501043 -36.064453,22.4625643 0,0 8.473892,-7.301896 16.169706,2.033941 -9.215496,-3.27439 -14.286326,0.345417 -14.286326,0.345417 -5.825164,5.825163 -3.997233,10.711894 -3.169144,13.802363 2.422913,9.042434 13.908811,5.456951 13.908811,5.456951 -11.720886,8.790664 -18.033203,-3.832031 -18.033203,-3.832031 2.254016,6.536648 2.705078,10.820312 2.705078,10.820312 -2.028615,10.819279 10.369141,10.367188 10.369141,10.367188 0,0 8.231373,6.33239 21.1875,4.507812 C 63.563106,69.197358 81.611351,58.021881 83.296659,56.725169 91.029259,50.775537 93.756622,36.629 80.700323,44.381945 86.049809,37.578985 91.0625,41.023438 91.0625,41.023438 93.522533,24.052472 77.523178,4.1907558 58.378906,9.3186857 Z"
      />
      <path
        id="path12"
        style={{ fill: darkPurple }}
        d="m 87.790485,42.261935 c 2.142905,0.637052 3.053443,3.950885 2.039373,7.42213 -1.014098,3.471237 -3.573084,5.800273 -5.731532,5.216422 -2.158448,-0.583852 -3.108203,-3.862 -2.127208,-7.342195 0.980999,-3.480193 3.659192,-5.831303 5.832672,-5.300778 l -1.889381,6.315183 z"
      />
      <path
        style={{ fill: darkPurple }}
        d="M 35.959226,31.058868 C 48.642591,21.938233 63.758642,15.816399 79.43322,14.83797 c 7.840325,4.696714 11.212773,14.217039 11.639928,22.954637 0.291802,1.959874 0.255525,3.178336 -1.792421,2.051886 -4.4654,-1.106727 -8.294942,2.551447 -9.882414,6.376265 -1.674064,3.303927 -1.149281,8.766393 3.022794,9.85862 3.087329,0.290752 -2.170175,2.529558 -3.070315,3.494747 -6.876176,4.389711 -14.044044,8.300397 -21.226226,12.161262 -5.15284,0.654316 -11.007761,-0.627602 -15.836283,-2.342834 0.260084,-3.766023 0.552452,-8.42417 -3.157705,-10.464528 -2.967311,-1.915827 -6.77043,-0.570449 -9.146782,1.668778 -1.964849,-2.807235 -5.832371,-3.741393 -8.89214,-2.250075 -3.483005,2.830594 -2.065044,-3.430822 -3.316388,-5.542212 -0.310345,-1.370212 -1.366616,-3.499243 -0.09208,-1.0315 3.214843,5.092143 10.068342,5.66786 15.389936,4.234518 3.472632,-0.59502 5.215501,-4.26435 7.225614,-6.823064 1.723775,-2.679329 1.721862,-5.970053 1.609676,-9.032821 -0.693772,-3.600995 -2.842021,-7.094953 -5.949186,-9.092781 z"
        id="path3"
      />
      <path
        style={{ fill: deepPurple }}
        d="m 79.699488,54.593557 c 0,0 -6.261232,6.896505 -19.421569,7.223877 0,0 -10.449341,-1.101798 -11.960844,-7.579149 0,0 -2.16583,-4.445404 -0.473697,-13.974056 0,0 7.924254,-10.345417 20.250538,-13.500359 0,0 8.939793,-1.791757 13.855632,3.197453 0,0 4.850472,3.182434 5.290697,9.418947 0,0 -4.400451,-0.613096 -6.822862,5.519849 0,0 -3.158167,4.762056 -0.717895,9.693438 z"
        id="path4"
      />
      <path
        style={{ fill: darkPurple }}
        d="m 56.710781,45.703659 c 0,0 3.673892,-0.662632 4.086356,4.53441 0,0 -1.055243,6.295339 -6.929388,2.062318 -0.574207,-0.306244 -2.929216,-5.456243 2.843032,-6.596728 z"
        id="path5"
      />
      <path
        id="path6"
        style={{ fill: darkPurple }}
        d="m 59.985486,41.917107 a 1.9714439,1.6460599 0 0 1 -1.956339,1.646011 1.9714439,1.6460599 0 0 1 -1.986318,-1.620788 1.9714439,1.6460599 0 0 1 1.925901,-1.670848 1.9714439,1.6460599 0 0 1 2.01583,1.595183 l -1.970518,0.05044 z"
      />
      <path
        id="path7"
        style={{ fill: mediumPurple }}
        d="m 74.435451,22.80166 a 3.4714165,2.6933403 31.109715 0 1 -4.340933,0.526052 3.4714165,2.6933403 31.109715 0 1 -1.624353,-4.077717 3.4714165,2.6933403 31.109715 0 1 4.316041,-0.588539 3.4714165,2.6933403 31.109715 0 1 1.690493,4.068698 l -3.013401,-1.722103 z"
      />
      <path
        id="path8"
        style={{ fill: mediumPurple }}
        d="m 81.228609,20.394749 a 2.2743762,2.3342283 34.921049 0 1 -3.18675,0.621891 2.2743762,2.3342283 34.921049 0 1 -0.563218,-3.19633 2.2743762,2.3342283 34.921049 0 1 3.178119,-0.67087 2.2743762,2.3342283 34.921049 0 1 0.61192,3.186048 L 79.363753,19.09279 Z"
      />
      <path
        id="path9"
        style={{ fill: mediumPurple }}
        d="m 48.480125,65.478096 a 1.9751163,1.5561522 0 0 1 -1.959983,1.556107 1.9751163,1.5561522 0 0 1 -1.990018,-1.532261 1.9751163,1.5561522 0 0 1 1.929488,-1.579587 1.9751163,1.5561522 0 0 1 2.019585,1.508055 l -1.974188,0.04769 z"
      />
      <path
        id="path10"
        style={{ fill: darkPurple }}
        d="m 26.68037,62.237604 a 2.8130443,2.3342283 57.173452 0 1 -0.42156,3.619459 2.8130443,2.3342283 57.173452 0 1 -3.477819,-1.052627 2.8130443,2.3342283 57.173452 0 1 0.368265,-3.63559 2.8130443,2.3342283 57.173452 0 1 3.483463,0.996915 l -1.913839,1.337221 z"
      />
      <path
        id="path11"
        style={{ fill: darkPurple }}
        d="m 39.51837,66.807515 a 3.8903804,3.3517125 31.758078 0 1 -5.046628,0.817854 3.8903804,3.3517125 31.758078 0 1 -1.595826,-4.86922 3.8903804,3.3517125 31.758078 0 1 5.022173,-0.89247 3.8903804,3.3517125 31.758078 0 1 1.672787,4.855544 l -3.360411,-1.959347 z"
      />
      <path
        id="path13"
        style={{ fill: deepPurple }}
        d="m 35.3433 44.4563 a 5.3855 7.1788 25.8808 0 1 -7.7207 5.006 a 5.3855 7.1788 25.8808 0 1 -2.6033 -8.25 a 5.3855 7.1788 25.8808 0 1 7.6808 -5.1324 a 5.3855 7.1788 25.8808 0 1 2.721 8.1714 Z"
      />
    </svg>
  )
}

