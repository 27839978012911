import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import StartGame from "./StartGame";
import exampleSet from "../study_set.json";
import {
  getNameFromEmail,
  shuffleArray,
  getPromptImgUrl,
  trimVal,
} from "../utils";
import ActivePrompt from "./ActivePrompt";
import ExpiredPrompt from "./ExpiredPrompt";
import Modal from "./Modal";
// import NextPromptTimeRemaining from "./NextPromptTimeRemaining";
import starryBackground from "./assets/imgs/starry_background.svg";
import continueIcon from "./assets/imgs/play_icon.svg";
import restartIcon from "./assets/imgs/restart_icon.svg";
import warningIcon from "./assets/imgs/warning_icon.svg";
import HomeIcon from "@mui/icons-material/Home";
import GameProgress from "./GameProgress";
import ModalChild from "./ModalChild";
import Button from "./Button";
import { btnColors, colors, niceColors } from "../shared-styles";
import PauseButton from "./PauseButton";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import RocketIcon from "@mui/icons-material/Rocket";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import StarIcon from "@mui/icons-material/Star";
import GamePreload from "./GamePreload";
import {
  get,
  getDatabase,
  orderByValue,
  query,
  ref,
  set,
} from "firebase/database";
import MyWarpDrive from "./MyWarpDrive";
import useIsTeacher from "../useIsTeacher";
import ConfirmGoHomeModal from "./ConfirmGoHomeModal";

const db = getDatabase();
const auth = getAuth();
const _levelSize = 7;
const _numTopHighscorers = 20;

interface IPromptData {
  e: string;
  c: string;
  i?: string;
}

export interface IPrompt {
  data: IPromptData;
  isLastChance: boolean;
  isPowerup: boolean;
}

export interface IActivePrompt {
  prompt: IPrompt;
  index: number;
  expiry: number;
  isExpired: boolean;
  isCompleted: boolean;
}

function TheGame({ demo = false }: { demo?: boolean }) {
  const { setId } = useParams();
  const [user] = useAuthState(auth);
  const isTeacher = useIsTeacher(auth);
  const [difficulty, setDifficulty] = useState<number>(1);
  const [powerups, setPowerups] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [leaderboard, setLeaderboard] = useState<[string, number][]>([]);
  const [userRank, setUserRank] = useState(0);
  const [nextPromptDelay, setNextPromptDelay] = useState(500);
  const [promptIndex, setPromptIndex] = useState(-1);
  const [streak, setStreak] = useState(0);
  const [recentlyFailed, setRecentlyFailed] = useState<IPrompt[]>([]);
  const [level, setLevel] = useState(1);
  const [isLevelling, setIsLevelling] = useState(false);
  const [allPrompts, setAllPrompts] = useState<IPrompt[]>([]);
  const [highscore, setHighscore] = useState<number>(0);
  const [powerupItems, setPowerupItems] = useState<IPromptData[]>([]);
  const [showConfirmGoHome, setShowConfirmGoHome] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, `powerupItems`);
    get(dbRef).then((snapshot) => {
      const val = snapshot.val();
      const entries = Object.entries(val ?? {}) as [string, IPromptData][];
      const items = entries.map(([id, data]) => ({
        ...data,
        i: data.i ? getPromptImgUrl(id) : undefined,
      }));
      setPowerupItems(items);
    });
  }, []);

  useEffect(() => {
    function onSetLoaded(set: IPromptData[]) {
      const allPrompts = set.map((obj) => ({
        data: obj,
        isLastChance: false,
        isPowerup: false,
      }));
      setAllPrompts(allPrompts);
      setPrompts(shuffleArray(allPrompts.slice(0)));
    }

    if (demo) {
      const getSrc = (img: string) => {
        try {
          return require(`./assets/vocab/${img}`);
        } catch (ex) {
          return null;
        }
      };
      onSetLoaded(
        exampleSet.map((obj) => ({ ...obj, i: getSrc(`${obj.e}.jpg`) }))
      );
    } else {
      const dbRef = ref(db, `setItems/${setId}`);
      get(dbRef).then((snapshot) => {
        const val = snapshot.val();
        const set = Object.entries(val ?? {}) as [string, IPromptData][];
        if (set.length === 0) {
          throw new Error("set data not found");
        }
        onSetLoaded(
          set.map(([id, data]) => ({
            ...data,
            i: data.i ? getPromptImgUrl(id) : undefined,
          }))
        );
      });
    }
  }, [demo, setId]);

  useEffect(() => {
    if (!demo) {
      const dbRef = ref(db, `highscores/${setId}/${user!.uid}`);
      get(dbRef).then(async (snapshot) => {
        setHighscore(snapshot.val());
      });
    }
  }, [demo, setId, user]);

  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [score, setScore] = useState(0);
  const [activePrompts, setActivePrompts] = useState<IActivePrompt[]>([]);
  const [pressEscNotification, setPressEscNotification] = useState(false);

  useEffect(() => {
    document.body.addEventListener(
      "blur",
      (e) => {
        const isInput =
          (e.target as HTMLElement).tagName.toLowerCase() === "input";
        if (isInput) {
          (e.target as HTMLInputElement).focus();
        }
      },
      true
    );
  }, []);

  const start = (difficulty: number, powerups: boolean) => {
    setDifficulty(difficulty);
    setPowerups(powerups);
    setIsStarted(true);
    setIsRunning(true);
    setTimeout(
      () =>
        (
          document.querySelector("input.answer-input") as HTMLInputElement
        ).focus(),
      100
    );
  };
  const pause = () => setIsRunning(false);
  const restart = () => {
    //reset all da tings
    setIsStarted(false);
    setIsRunning(false);
    setIsGameOver(false);
    setNextPromptDelay(500);
    const newAllPrompts = allPrompts.map((p) => ({
      ...p,
      isLastChance: false,
    }));
    setAllPrompts(newAllPrompts);
    setPromptIndex(-1);
    setStreak(0);
    setRecentlyFailed([]);
    setLevel(1);
    setScore(0);
    setLeaderboard([]);
    setActivePrompts([]);
    setPrompts(shuffleArray(newAllPrompts.slice(0)));
    setPressEscNotification(false);
    for (const elem of document.querySelectorAll<HTMLInputElement>("input")) {
      elem.value = "";
    }
  };

  const navigate = useNavigate();
  const goHome = () => {
    pause();
    setShowConfirmGoHome(true);
  };
  const confirmGoHome = () => navigate("home");

  const loadPrompts = useCallback(() => {
    let newPrompts = [...prompts];
    while (newPrompts.length - promptIndex <= _levelSize) {
      newPrompts = [...newPrompts, ...shuffleArray(allPrompts.slice(0))];
    }
    return newPrompts;
  }, [allPrompts, promptIndex, prompts]);

  const promptInterval = useMemo(() => {
    const baseValue = [25000, 17000, 3500][difficulty];
    const decay = difficulty > 1 ? 0.95 : 0.9; // Adjusted decay for interval
    return Math.max(1000, baseValue * Math.pow(decay, level - 1)); // Minimum interval set to 1000ms
  }, [level, difficulty]);

  const promptExpiry = useMemo(() => {
    const baseValue = [25000, 17000, 17000][difficulty];
    const decay = 0.85; // Adjusted decay for expiry to be more aggressive
    return Math.max(1000, baseValue * Math.pow(decay, level - 1)); // Minimum expiry set to 1000ms
  }, [level, difficulty]);

  const onPromptComplete = useCallback((activePrompt: IActivePrompt) => {
    const { prompt } = activePrompt;
    setActivePrompts((prompts) =>
      prompts.map((p) =>
        p.prompt === prompt ? { ...p, isCompleted: true } : p
      )
    );
    setTimeout(() => {
      setActivePrompts((prompts) => prompts.filter((p) => p.prompt !== prompt));
    }, 1000);
  }, []);

  const tryProgress = useCallback(
    (activePrompt?: IActivePrompt) => {
      const input = document.querySelector(
        "input.answer-input"
      ) as HTMLInputElement;
      const value = input.value.toLowerCase();
      input.value = "";

      if (!value) return null;
      const promptMatch = (activePrompt ? [activePrompt] : activePrompts).find(
        (p) => trimVal(value) === trimVal(p.prompt.data.e).toLowerCase() && !p.isCompleted
      );
      if (promptMatch) {
        if (promptMatch.prompt.isPowerup) {
          setScore((s) => s + 500);
        } else {
          const newStreak = streak + 1;
          setStreak(newStreak);
          setScore(
            (s) =>
              s +
              150 *
              (difficulty === 0 ? 1 : difficulty > 1 ? level + 6 : level) +
              20 * newStreak
          );
        }
        onPromptComplete(promptMatch);
        return true;
      } else {
        return false;
      }
    },
    [activePrompts, difficulty, level, onPromptComplete, streak]
  );

  const onExpired = useCallback(
    (activePrompt: IActivePrompt) => {
      // one last chance to progress
      if (!tryProgress(activePrompt)) {
        const { prompt } = activePrompt;
        if (prompt.isPowerup) {
          setTimeout(() => {
            setActivePrompts((prompts) =>
              prompts.filter((p) => p.prompt !== prompt)
            );
          }, 1000);
        } else {
          setIsRunning(false);
          setStreak(0);
          setRecentlyFailed((arr) => [...arr, prompt]);
        }
        setActivePrompts((prompts) =>
          prompts.map((p) =>
            p.prompt === prompt
              ? {
                ...p,
                isExpired: true,
              }
              : p
          )
        );
      }
    },
    [tryProgress]
  );

  const loadNextPrompt = useCallback(
    (prompts: IPrompt[]) => {
      const newPromptIndex = promptIndex + 1;
      setNextPromptDelay(promptInterval);
      const prompt = prompts[newPromptIndex];
      setPromptIndex(newPromptIndex);
      setActivePrompts((p) => {
        const newPrompt = {
          prompt,
          index: newPromptIndex,
          expiry: prompt.isPowerup ? promptExpiry / 2 : promptExpiry,
          isExpired: false,
          isCompleted: false,
        };
        return [...p, newPrompt];
      });
    },
    [promptExpiry, promptIndex, promptInterval]
  );

  const levelUp = useCallback(
    (prompts: IPrompt[]) => {
      setIsLevelling(true);
      setLevel((l) => l + 1);
      const nextPrompts = [
        ...recentlyFailed,
        ...prompts.slice(
          promptIndex + 1,
          promptIndex + 1 + _levelSize - recentlyFailed.length
        ),
      ];

      if (powerups && powerupItems.length) {
        const nextPowerup =
          powerupItems[Math.floor(Math.random() * powerupItems.length)];
        nextPrompts.push({
          data: nextPowerup,
          isLastChance: false,
          isPowerup: true,
        });
      }
      // console.log(
      //   `next prompts:`,
      //   nextPrompts.map((p) => p.data.e)
      // );
      // replace the default next prompts with the array we created
      setPrompts((p) => {
        p.splice.apply(p, [
          promptIndex + 1,
          promptIndex + 1 + _levelSize - recentlyFailed.length,
          ...shuffleArray(nextPrompts),
        ]);
        return p;
      });
      setRecentlyFailed([]);

      window.setTimeout(() => {
        setIsLevelling(false);
        setTimeout(
          () =>
            (
              document.querySelector("input.answer-input") as HTMLInputElement
            ).focus(),
          100
        );
      }, 4000);
    },
    [powerupItems, powerups, promptIndex, recentlyFailed]
  );

  const levelCompleting = useMemo(() => {
    if (level === 1 || !powerups) {
      return promptIndex + 1 >= level * _levelSize;
    } else {
      return promptIndex + 2 >= level * (_levelSize + 1);
    }
  }, [level, powerups, promptIndex]);

  const expiredPrompt = useMemo(
    () => activePrompts.find((p) => p.isExpired && !p.prompt.isPowerup),
    [activePrompts]
  );

  // for timeout display purposes (can remove if we don't display time remaining)
  // const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const [setTimeoutStart, setSetTimeoutStart] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    let timeoutId!: number;
    if (isRunning) {
      const newPrompts = loadPrompts();
      if (
        levelCompleting &&
        activePrompts.filter((p) => !p.isCompleted).length === 0
      ) {
        console.log("level up");
        levelUp(newPrompts);
      } else if (!levelCompleting && !isLevelling && !expiredPrompt) {
        timeoutId = window.setTimeout(() => {
          console.log("load next prompt");
          loadNextPrompt(newPrompts);
        }, nextPromptDelay);
        // setTimeoutId(timeoutId);
        setSetTimeoutStart(Date.now());
      }
    }
    return () => window.clearTimeout(timeoutId);
  }, [
    activePrompts,
    expiredPrompt,
    isLevelling,
    isRunning,
    levelCompleting,
    levelUp,
    loadNextPrompt,
    loadPrompts,
    nextPromptDelay,
  ]);

  useEffect(() => {
    if (!isRunning && setTimeoutStart) {
      setNextPromptDelay((t) => t - (Date.now() - setTimeoutStart));
    }
  }, [isRunning, setTimeoutStart]);

  useEffect(() => {
    if (
      promptIndex >= 0 &&
      activePrompts.filter((p) => !p.isCompleted).length === 0
    ) {
      setNextPromptDelay((t) => Math.min(t, 0));
    }
  }, [activePrompts, promptIndex]);

  // const placeholder = useMemo(() => {
  //   return `Type ${activePrompts
  //     .map((p) => `'${p.prompt.data.e}'`)
  //     .join("/")}`;
  // }, [activePrompts]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const result = tryProgress();
    if (result === false) {
      setScore(Math.max(0, score - 10));
    }
  }

  useEffect(() => {
    (document.querySelector("input.answer-input") as HTMLInputElement).value =
      "";
  }, [expiredPrompt]);

  async function onContinue() {
    const shouldEnd = expiredPrompt?.prompt.isLastChance;
    if (expiredPrompt) {
      if (shouldEnd) {
        setIsGameOver(true);

        if (!demo) {
          const isHighscore = score > (highscore ?? 0);
          const promises = [];
          if (isHighscore) {
            setHighscore(score);
            if (!isTeacher) {
              const dbRef = ref(db, `highscores/${setId}/${user!.uid}`);
              promises.push(set(dbRef, score));
            }
          }

          // also update weekly/monthly highscores
          if (!isTeacher) {
            promises.push(
              set(ref(db, `weeklyHighscores/${setId}/${user!.uid}`), score)
            );
            promises.push(
              set(ref(db, `monthlyHighscores/${setId}/${user!.uid}`), score)
            );

            await Promise.all(promises).catch((err) => null);
          }

          const topScoresRef = query(
            ref(db, `highscores/${setId}`),
            orderByValue()
            // limitToLast(_numTopHighscorers)
          );

          await get(topScoresRef).then((snapshot) => {
            const val = (snapshot.val() as { [key: string]: number }) ?? {};
            const scoresArray = Object.entries(val).sort(
              ([_, x], [__, y]) => y - x
            );
            const rank =
              scoresArray.findIndex(([u, s]) => u === user?.uid || s < score) +
              1;
            setUserRank(rank || (scoresArray.length + 1));
            setLeaderboard(scoresArray);
          });
        }
      }
      expiredPrompt.prompt.isLastChance = true;
      setActivePrompts((prompts) => prompts.filter((p) => !p.isExpired));
    }

    if (!shouldEnd) {
      setIsRunning(true);
      setTimeout(
        () =>
          (
            document.querySelector("input.answer-input") as HTMLInputElement
          ).focus(),
        100
      );
    }
  }

  useEffect(() => {
    let timeoutId!: number;
    if (isStarted) {
      timeoutId = window.setTimeout(() => {
        setPressEscNotification(true);

        timeoutId = window.setTimeout(() => {
          setPressEscNotification(false);
        }, 5000);
      }, 8500);
    }
    return () => window.clearTimeout(timeoutId);
  }, [isStarted]);

  const expiringPrompt = useMemo(
    () => activePrompts.find((p) => p.prompt.isLastChance),
    [activePrompts]
  );

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      switch (event.key) {
        case "Esc":
        case "Escape":
          setPressEscNotification(false);
          if (activePrompts[0] && !activePrompts[0].isCompleted)
            onExpired(activePrompts[0]);
          break;
        default:
          return;
      }

      event.preventDefault();
    };

    window.addEventListener("keydown", onKeyDown, true);
    return () => window.removeEventListener("keydown", onKeyDown, true);
  }, [activePrompts, onExpired]);

  const notificationChild = useMemo(() => {
    if (expiringPrompt)
      return (
        <div className="warning">
          <img src={warningIcon} alt="warning" />
          <span className="warning-text">warning!</span>
          <img src={warningIcon} alt="warning" />
        </div>
      );
    else if (pressEscNotification)
      return <div className="info">press esc to skip</div>;
  }, [expiringPrompt, pressEscNotification]);

  function buildHighscoreEntry(
    index: number,
    name: string,
    score: number,
    rank?: number
  ) {
    return (
      <div key={index} className="highscore">
        {!!user && name === getNameFromEmail(user) && (
          <StarIcon className="star" />
        )}
        {rank && <div className="highscore-rank">{rank}.</div>}
        <div className="highscore-name">{name}</div>
        <div className="highscore-score">{score.toLocaleString()}</div>
      </div>
    );
  }

  return (
    <StyledTheGame>
      <GamePreload />
      {!isStarted && (
        <StartGame
          hasPowerups={powerupItems.length > 0}
          onStart={start}
          canStart={allPrompts.length > 0}
        />
      )}
      <div className="top">
        {isStarted && (
          <button onClick={restart}>
            <img src={restartIcon} alt="restart" />
          </button>
        )}
        <span className="spacer"></span>
        <div className="notification">{notificationChild}</div>
        {isStarted && (
          <>
            {isStarted &&
              (isRunning ? (
                <PauseButton onClick={pause} disabled={!!expiredPrompt} />
              ) : (
                <button className="continue" onClick={onContinue}>
                  <img src={continueIcon} alt="continue" />
                </button>
              ))}
            <button onClick={goHome} title="home">
              <HomeIcon className="home-icon"></HomeIcon>
            </button>
          </>
        )}
      </div>
      <div className={`active-prompts ${isLevelling ? "levelling" : ""}`}>
        {isLevelling && <div className="levelling-background"></div>}
        <MyWarpDrive on={isLevelling} isRunning={isRunning} level={level} />
        {activePrompts.map((p) => {
          const props = {
            ...p,
            isRunning,
            onExpired: () => onExpired(p),
          };
          return <ActivePrompt key={p.index} {...props} />;
        })}
        {isLevelling && (
          <div key={level} className="level-hero">
            {/* <RocketIcon></RocketIcon> */}
            <div className="level-hero-text">Level {level}</div>
          </div>
        )}
        <div className="bottom">
          <span className="flex1"></span>
          <form className="answer-input-form" onSubmit={onSubmit}>
            <input
              autoFocus
              autoComplete="false"
              aria-autocomplete="none"
              className="answer-input"
              type="text"
              title="answer input"
              disabled={!isRunning || isLevelling}
              placeholder={isRunning && !isLevelling ? "TYPE YOUR ANSWER" : ""}
            />
            <input type="submit" hidden />
          </form>
          <span className="flex1">
            <div className="game-progress-container">
              <GameProgress level={level} score={score} streak={streak} />
            </div>
          </span>
        </div>
      </div>

      <div className="ground"></div>

      {expiredPrompt && (
        <ExpiredPrompt
          {...{ prompt: expiredPrompt, onAnswered: onContinue }}
        />
      )}
      {isGameOver && (
        <Modal>
          <div className="game-over">
            <h1>Your planet was destroyed!</h1>
            <div className="game-over-summary">
              <ModalChild>
                <GameProgress level={level} score={score} />
                {leaderboard.length && (
                  <>
                    <div className="highscores-title">
                      <RocketIcon />
                      Highest Scorers
                      <RocketIcon />
                    </div>
                    <div className="highscores">
                      <div className="highscore-col">
                        {leaderboard
                          .slice(0, 10)
                          .map(([name, value], index) =>
                            buildHighscoreEntry(index, name, value, index + 1)
                          )}
                      </div>
                      <div className="highscore-col">
                        {leaderboard
                          .slice(10, _numTopHighscorers)
                          .map(([name, value], index) =>
                            buildHighscoreEntry(index, name, value, index + 11)
                          )}
                      </div>
                    </div>
                    {leaderboard
                      .slice(0, _numTopHighscorers)
                      .every(([name]) => name !== user!.uid) && (
                        <>
                          <hr className="highscore-divider" />
                          <div className="highscores">
                            <div className="highscore-col">
                              {buildHighscoreEntry(
                                _numTopHighscorers,
                                user!.uid,
                                highscore ?? 0,
                                userRank
                              )}
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </ModalChild>
            </div>
            <div className="game-over-options">
              <Button
                icon={RocketLaunchIcon}
                width={200}
                className="game-over-option"
                onClick={restart}
                buttonText="PLAY AGAIN"
                color={btnColors.launch}
              />
              <Button
                width={200}
                className="game-over-option"
                onClick={confirmGoHome}
                buttonText="HOME"
                color={btnColors.home}
              />
            </div>
          </div>
        </Modal>
      )}
      {showConfirmGoHome && (
        <ConfirmGoHomeModal
          cancel={() => {
            setShowConfirmGoHome(false);
            onContinue();
          }}
          confirm={confirmGoHome}
        />
      )}
    </StyledTheGame>
  );
}

export default TheGame;

const StyledTheGame = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #1e163d;

  .top {
    background-color: #1e163d;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 3.5rem;
    color: white;
    padding: 5px;

    .spacer {
      flex: 1;
    }

    .notification {
      font-size: min(5vw, 2rem);
      position: absolute;
      width: 99%;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: min(5vw, 2rem);
        height: min(5vw, 2rem);
      }

      .warning {
        color: orange;

        .warning-text {
          padding-left: min(2vw, 20px);
          padding-right: min(2vw, 20px);
        }
      }

      .info {
        color: cyan;
      }
    }

    button {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 2.2rem;
        height: 2.2rem;
      }

      .home-icon {
        width: 3rem;
        height: 3rem;
        color: #846dc6;
      }
    }

    .continue img {
      transform: rotate(180deg);
    }
  }

  @keyframes levelling {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .active-prompts {
    z-index: 0;
    background-image: url(${starryBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    flex: 1;
    position: relative;

    .levelling-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${niceColors.darkDarkPurple};
      animation: levelling 0.5s forwards, levelling 0.5s reverse;
      animation-delay: 0s, 3.5s;
    }
    @keyframes fly-in {
      0% {
        transform: scale(0);
      }
      80% {
        transform: scale(1.02);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes fly-beyond {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(0.99) translateY(1%);
      }
      100% {
        transform: scale(30) translateY(-100%);
      }
    }
    .level-hero {
      transform: scale(0);
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fly-in 0.5s ease-out forwards, fly-beyond 1s ease-in forwards;
      animation-delay: 0.5s, 3s;

      .level-hero-text {
        font-size: 15vw;
        font-weight: bold;
      }
    }
  }

  .ground {
    background-color: brown;
    height: 5px;
  }

  .bottom {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: 0;
    backdrop-filter: blur(1px);

    .flex1 {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 500px) {
      flex-direction: column-reverse;
      .game-progress-container {
        padding-left: 0;
      }
    }

    padding: 10px 30px;

    form {
      flex: 2;
      display: flex;
      justify-content: center;
      margin-block: 5px;
    }

    .game-progress-container {
      position: relative;
      padding-left: 20px;
    }
  }

  .game-over {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 3rem;
      margin-block: 0;
    }

    .leaderboard {
      display: flex;

      .col {
        flex: 1;
      }
    }

    .game-over-summary {
      margin-block: 30px;

      .game-progress {
        justify-content: center;
      }

      .highscores-title {
        font-weight: bold;
        margin-block: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .highscores {
        display: flex;
        justify-content: flex-end;

        .highscore-col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .highscore-divider {
          width: 100%;
          height: 1px;
          background-color: rgb(50, 50, 50);
          border: none;
        }
      }

      .highscore {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-left: 8px;
        margin-right: 8px;
        font-size: 0.9rem;

        .highscore-rank {
          width: 1.2rem;
          text-align: start;
        }

        .highscore-name {
          margin-left: 5px;
          margin-right: 20px;
          width: 130px;
          text-align: start;
          text-transform: none;
        }

        .highscore-score {
          color: yellow;
          width: 70px;
          text-align: end;
        }

        .star {
          color: yellow;
          font-size: 1rem;
          position: absolute;
          left: 0;
          transform: translateX(-120%);
        }
      }
    }

    .leaderboard-container {
      margin-top: 10px;
    }

    .game-over-message {
      font-size: 2rem;
    }

    .game-over-options {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .game-over-options > div:not(:first-child) > div {
      margin-top: 10px;
    }
  }

  .answer-input {
    flex: 1;
    max-width: 500px;
    background: ${niceColors.darkDarkPurple};
  }

  #expired-input {
    background-color: ${colors.modalChildBackgroundColor};
  }
`;
