import styled from "styled-components/macro";
import nweImg from "./TheGame/assets/imgs/orbiting_asteroids_logo.svg";

function Logo() {
  return <StyledLogo className="nwe-logo" src={nweImg} alt="logo"></StyledLogo>;
}
export default Logo;

const StyledLogo = styled.img`
  width: 50px;
  height: 50px;
`;
