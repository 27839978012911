/// MatchGame.tsx
"use client"

import styled from "styled-components"
import * as motion from "motion/react-client"
import { useEffect, useMemo, useState } from "react"
import MissionGuy from "../MissionGuy"
import { shuffleArray, shuffleThorough } from "../utils"
import { allAsteroids, type IPromptData } from './TheAsteroids'
import Button from "../TheGame/Button"
import { colors, niceColors } from "../shared-styles"
import FloatAround from "../FloatAround"

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    hues: number[],
    asteroids: number[],
    prompts: IPromptData[],
    onAsteroidFound: (hueIndex: number) => void,
    onWrong: VoidFunction,
    onComplete: VoidFunction,
    children?: React.ReactNode,
}

const spring = { type: "spring", bounce: 0.5, duration: 0.8 }


const initialOrder = [...Array(12).keys()]
export default function MatchGame({ hues, asteroids, prompts, onAsteroidFound, onWrong, onComplete, children }: IProps) {
    const [order, setOrder] = useState(initialOrder.slice(0, prompts.length * 2))
    const [selected, setSelected] = useState<number | null>(null)
    const [matched, setMatched] = useState<number[]>([])
    const [guyColor, setGuyColor] = useState<string | null>(null)

    const numItems = useMemo(() => prompts.length * 2, [prompts])
    useEffect(() => {
        setOrder(shuffleArray([...Array(numItems).keys()]))
    }, [numItems])
    const columns = useMemo(() => {
        if (numItems <= 4) return 2
        if (numItems <= 6) return 3
        else return 4
    }, [numItems])

    const handleClick = (i: number) => {
        if (selected === null) {
            setSelected(i);
        } else if (selected === i) {
            setSelected(null);
        } else {
            if (Math.floor(i / 2) === Math.floor(selected / 2)) {
                // correct
                setMatched((prev) => [...prev, i, selected]);
                onAsteroidFound(Math.floor(i / 2));
                setOrder(order => order.map(j => {
                    if (j === selected) return i;
                    else if (j === i) return selected;
                    else return j;
                }));
                if (matched.length + 2 >= numItems) {
                    onComplete();
                }
            } else {
                onWrong();
                setOrder((order) => {
                    const unmatched = order.filter(i => !matched.includes(i)).reverse()
                    const shuffled = shuffleThorough(unmatched)
                    return order.map(i => matched.includes(i) ? i : shuffled.pop()!)
                })
            }
            setSelected(null);
        }
    };

    return (
        <StyledMatchGame className="match-game">
            <MissionGuy color={guyColor} />
            <TopSection>
                {children}
            </TopSection>
            <GameContainer>
                {order.map((i, index) => {
                    let isMatched = matched.includes(i)
                    return <StyledCell
                        key={i}
                        layoutId={`item-${i}`}
                        transition={{
                            layout: spring,
                        }}
                        whileHover={{ scale: isMatched ? 1 : 1.1 }}
                    >
                        <FloatAround
                            index={index}
                        >
                            <div
                                className={`cell-item ${isMatched ? 'matched' : ''}`}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: `min(${90 / columns}vw, ${80 / columns}vh)`,
                                    aspectRatio: "1/1",
                                }}
                            >
                                {isMatched ?
                                    (() => {
                                        let pairIndex = Math.floor(i / 2);
                                        let hue = hues[pairIndex];
                                        const AsteroidComponent = allAsteroids[asteroids[pairIndex % asteroids.length]];
                                        return (
                                            <div
                                                onMouseEnter={() => setGuyColor(`hsl(${hue}, 60%, 50%)`)}
                                                onMouseLeave={() => setGuyColor(null)}
                                            >
                                                <AsteroidComponent hue={hue} />
                                            </div>
                                        );
                                    })() :
                                    (() => {
                                        let prompt = prompts[Math.floor(i / 2)];
                                        if (prompt === undefined) {
                                            console.log(order)
                                            debugger
                                        }
                                        return <Button
                                            className={`prompt-button ${i % 2 === 0 ? 'e' : 'c'} ${selected === i ? 'selected' : ''}`}
                                            onClick={() => handleClick(i)}
                                            selected={selected === i}
                                            buttonText={i % 2 === 0 ? prompt.e : prompt.c}
                                            color={i % 2 === 0 ? niceColors.darkDarkPurple : colors.asteroid}
                                            textColor={i % 2 === 0 ? "#cacaca" : undefined}
                                            borderRadius={70}
                                            depth={5}
                                        >
                                            {i % 2 !== 0 && prompt.i && <img
                                                src={prompt.i}
                                                alt="prompt"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    opacity: 0.25,
                                                }}
                                            ></img>}
                                        </Button>
                                    })()
                                }
                            </div>
                        </FloatAround>
                    </StyledCell>
                })}
            </GameContainer>
            <BottomSection />
        </StyledMatchGame>
    );
}

// Styled components
const StyledMatchGame = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .mission-guy:not([data-color]) {
    display: none !important;
  }
`;

const TopSection = styled.div`
  flex: 1; /* This will push the GameContainer to the center */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const BottomSection = styled.div`
  flex: 1; /* This will push the GameContainer to the center */
`;

const GameContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: min(100vw, 90vh);

  .styled-button {
    flex: 1;
    height: 96%;
    .prompt-button {
        height: 96%;
        &.selected {
            &.e {
                filter: brightness(5);
            }
            &.c {
                filter: brightness(1.5);
            }
        }

        .game-btn {
            height: 96%;
            width: 96%;
            margin: 0;

            &[data-selected="true"]:not([data-disabled="true"]) {
                .button-top {
                    margin-top: 0px;
                    transform: translateY(10px);
                }
                .button-bot {
                    transform: translateY(0px);
                    height: calc(96% - 10px);
                }
            }

            .button-top {
                height: calc(96% - 5px);
                .btn-text {
                    overflow-wrap: break-word;
                    font-size: clamp(0.8rem, 3vh, 1.5rem);
                }
            }

            .button-top, .button-bot {
                border-radius: 15px;
            }


        }
    }
  }
`;

const StyledCell = styled(motion.li) <{ isSelected: boolean }>`
  border: ${props => props.isSelected ? '5px solid transparent' : 'none'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;