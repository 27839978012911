import { btnColors } from "../shared-styles";
import LinkIcon from '@mui/icons-material/Link';
import { SmallButton } from "../TheGame/Button";

interface Props {
  id: string;
}

const CopySetUrlButton = ({ id }: Props) => {
  const handleCopy = () => {
    const url = `${window.location.origin}/mission-select/${encodeURIComponent(id)}`;
    navigator.clipboard.writeText(url);
  };

  return (
    <SmallButton
      color={btnColors.copyUrl}
      buttonText="Copy URL"
      onClick={handleCopy}
      icon={LinkIcon}
    />
  );
};

export default CopySetUrlButton; 