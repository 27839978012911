/// Asteroid4.tsx
interface Asteroid4Props {
  hue: number
}

export default function Asteroid4({ hue }: Asteroid4Props) {
  // Yellow: #feb420
  const yellow = `hsl(${(hue - 25) % 360}, 99%, 86%)`;
  // Orange: #f36326 (base hue 18)
  const orange = `hsl(${hue}, 90%, 61%)`;
  // Dark Orange: #da3e29
  const darkOrange = `hsl(${(hue - 10) % 360}, 76%, 51%)`;
  // Brown: #a11400
  const brown = `hsl(${(hue + 10) % 360}, 100%, 31%)`;
  // Dark Brown: #6b191f
  const darkBrown = `hsl(${(hue + 20) % 360}, 62%, 26%)`;
  // Deep Brown: #410d14
  const deepBrown = `hsl(${(hue + 25) % 360}, 65%, 16%)`;

  return (
    <svg className="asteroid4" width="100%" height="100%" viewBox="0 0 717 492" xmlns="http://www.w3.org/2000/svg">
      {/* Dark orange shapes */}
      <path
        d="m 326.28343,441.80177 c 0,0 25.33951,21.76217 60.36765,-0.14905 l -35.32626,-15.05465 z"
        id="path2"
        style={{ fill: darkOrange }} />
      <path
        d="m 144.07969,462.06684 c 0,0 -96.429765,19.5532 -95.174806,-81.78921 0,0 -107.352641,-178.70089 113.830336,-287.526987 0,0 11.06641,-29.329849 48.75822,-33.407455 6.2809,-0.679487 96.75421,-84.384584 222.55742,-11.645291 L 318.38346,444.78289 c 0,0 -87.55322,55.44227 -174.30377,17.28395 z"
        id="path3"
        style={{ fill: yellow }} />
      <path
        d="m 351.47388,426.59807 c 0,0 -92.04387,73.31869 -197.81791,8.48676 -2.08678,5.96224 -84.182873,-1.7127 -64.278078,-64.08226 0,0 -19.537984,-38.34235 -19.537984,-64.57619 0,0 43.211422,79.22028 120.720502,14.82812 -0.70204,7.72242 -68.72005,34.70751 -96.186157,-26.63816 0,0 -23.169183,-43.5088 15.723017,-85.70055 17.49151,-18.97546 43.43734,-34.26638 86.36545,-11.60988 -11.36305,-7.522 -46.12877,-47.45436 -107.928016,-2.52956 0,0 26.954176,-48.52053 71.670956,-69.98458 0,0 24.83379,32.93698 62.39588,2.52957 0,0 -44.03164,13.05953 -50.59126,-22.76607 0,0 -2.27327,-20.209345 16.39641,-38.374438 0,0 21.82829,-15.914765 36.96587,-14.905593 0,0 13.29788,-1.607708 27.42629,12.520698 0,0 75.1057,-61.7204574 179.75642,-18.263803 0,0 40.44475,20.356138 46.51048,31.678837 0,0 57.48594,-24.330235 122.60417,11.846559 l -93.98298,337.10118 c 0,0 -64.95149,45.43418 -153.67614,2.08114"
        id="path4"
        style={{ fill: orange }} />
      <path
        d="m 602.5988,89.937243 c 0,0 75.03075,18.160877 91.78742,93.976597 0,0 29.52527,110.8649 -66.9263,164.80761 z"
        id="path1"
        style={{ fill: darkOrange }} />
      <path
        d="m 384.53378,404.01301 c 0,0 -20.41953,24.5183 -91.12686,24.7764 0,0 4.47093,-8.7737 -4.45156,-10.2757 0,0 -5.50663,-0.4523 -3.88257,10.0115 0,0 -25.7144,-0.3367 -49.19757,-14.8536 0,0 20.17,-25.3038 -11.33837,-51.2326 0,0 -23.01217,-19.3711 -49.97279,-2.5196 0,0 -8.20744,-11.3194 -10.30634,-20.3895 66.92413,-34.4408 59.81441,-80.56725 59.81441,-80.56725 0,0 9.62429,-39.03061 -41.32984,-73.34143 26.14723,-29.60246 53.15926,-41.76437 53.60563,-44.32844 3.8396,0.7727 10.56221,7.12548 13.41407,3.8052 6.17173,-3.28774 -0.15187,-7.87848 -4.18945,-9.95495 7.5698,-3.36122 78.11242,-49.895001 78.11242,-49.895001 0,0 29.80358,-13.90631 43.80635,-15.9067 0,0 -7.7873,26.631136 17.68037,29.683766 0,0 31.1719,-0.28636 15.37435,-33.374146 0,0 36.6148,4.40131 39.97333,6.76472 0,0 14.97446,-2.40988 6.76471,-17.83426 0,0 43.11971,27.10573 48.58297,42.433216 0,0 52.23841,-25.022776 102.08571,-8.60964 0,0 98.81239,45.257255 82.40655,160.508275 0,0 0.7733,52.85354 -50.42789,95.93594 0,0 -109.05658,74.3059 -126.06971,81.1766 0,0 -61.26533,21.3406 -119.32792,-22.0128 z"
        id="path5"
        style={{ fill: brown }} />
      <path
        d="m 186.61547,105.25251 c -5.56168,-6.918262 -4.82005,-17.204489 -0.11923,-24.366199 2.31348,-3.54561 5.61822,-6.286866 8.76236,-9.0648 7.17635,-5.869995 16.83242,-9.907526 26.17138,-7.618379 7.63489,1.617526 14.0741,9.076635 13.16603,17.073778 -0.98435,9.434525 -7.5819,17.168075 -15.01055,22.533 -5.59965,4.36697 -12.63311,7.14604 -19.80964,6.6599 -4.79537,-0.26527 -9.54403,-2.01587 -13.16035,-5.2173 z"
        id="path6"
        style={{ fill: brown }} />
      <path
        d="m 146.85394,210.26815 c 23.29659,2.57104 40.15949,26.75196 37.64787,49.47391 -1.18148,15.01581 -8.59144,29.70118 -21.25526,38.20939 -13.59003,9.98677 -33.11245,9.94821 -46.94697,0.45908 -21.154017,-14.44547 -26.261427,-47.20472 -10.36261,-67.35976 9.1036,-12.91113 24.54113,-23.12443 40.91697,-20.78262 z"
        id="path7"
        style={{ fill: brown }} />
      <path
        d="m 116.34543,376.60631 c 6.95397,-2.83529 15.11763,0.5044 19.31396,6.44093 3.97912,4.70174 7.32608,11.3599 4.85094,17.52955 -2.34067,5.59706 -8.4352,9.28813 -14.49829,8.64061 -7.2178,-0.17685 -13.32065,-5.79984 -15.27449,-12.5513 -1.65674,-5.53875 -2.32513,-12.31058 1.70105,-17.0349 1.06139,-1.27667 2.40014,-2.3244 3.90683,-3.02489 z"
        id="path8"
        style={{ fill: brown }} />
      <path
        d="m 180.66149,377.21559 c 7.4708,-7.02084 19.90799,-9.68877 28.63267,-3.36988 6.72111,4.07965 13.03216,10.25695 13.83042,18.48673 0.95328,7.63292 -0.87782,16.98614 -8.24225,20.91518 -10.10973,5.6187 -23.39193,1.91689 -31.09225,-6.12238 -6.86543,-7.39617 -9.8835,-19.35744 -4.15328,-28.28128 0.32146,-0.5551 0.66363,-1.09818 1.02469,-1.62837 z"
        id="path9"
        style={{ fill: brown }} />
      <path
        d="m 396.24364,357.71995 c 0,0 -69.1522,40.36466 -128.15022,-37.73748 0,0 -36.54029,-82.63526 31.4479,-132.08122 -7.71199,-6.19006 -0.14123,-12.48254 -0.14123,-12.48254 0,0 9.85901,-2.03433 9.24521,8.24689 0,0 54.63861,-40.70718 66.77903,-43.96543 7.97293,-3.64477 45.39407,-16.17814 63.34039,-3.90228 0,0 6.49445,-10.7101 12.03272,-9.02454 0,0 8.99463,0.38936 1.28922,10.74351 0,0 9.33215,3.50472 15.352,9.76537 0,0 52.43057,-45.64392 108.84777,-28.68415 0,0 81.20641,30.14559 84.35744,141.61329 0,0 -4.49539,37.54402 -38.36897,59.99511 0,0 -74.68903,58.543 -109.52596,66.97129 0,0 -72.11631,20.55002 -116.5053,-29.45782 z"
        id="path10"
        style={{ fill: darkBrown }} />
      <path
        id="path11"
        d="m 267.92226,385.35401 c -4.18011,-1.37754 -9.33973,-0.52011 -12.26141,2.95418 -0.86529,1.09657 -1.51473,2.34876 -2.12678,3.59914 -2.27562,5.26394 0.27951,11.95641 5.37471,14.51497 2.70962,1.50789 5.9848,1.83655 8.96825,1.03844 3.65809,-0.9133 7.14733,-3.56885 8.05891,-7.36586 0.67451,-2.59707 -0.78007,-12.5253 -8.01368,-14.74087 z"
        style={{ fill: orange }} />
      <path
        d="m 234.05081,318.75162 c 2.34238,-2.97258 7.86091,-2.90892 9.76523,0.46301 0.73051,2.73299 0.45224,5.64351 0.46924,8.45344 -0.13757,3.11797 -0.58247,6.22582 -1.37956,9.24466 -2.70637,2.58088 -7.58685,2.43224 -9.99596,-0.48538 -0.9185,-3.06153 -0.53432,-6.33313 -0.43809,-9.48316 0.23346,-2.77629 0.74528,-5.53277 1.57914,-8.19257 z"
        id="path12"
        style={{ fill: darkBrown }} />
      <path
        d="m 337.31891,105.08414 c 0.76364,-4.61214 7.6418,-4.268 7.73882,-0.86276 1.51388,2.83663 0.77427,4.73389 0.94804,7.30336 9.9e-4,3.15498 -0.18352,8.08517 -1.00238,11.11946 -1.55845,2.18372 -5.03764,2.13012 -7.23308,0.58937 -1.88848,-0.761 -1.73825,-4.06944 -2.02726,-5.75033 -0.44933,-4.18146 0.12963,-8.45565 1.57586,-12.3991 z"
        id="path13"
        style={{ fill: darkBrown }} />
      <ellipse
        id="path15"
        cx="325.11206"
        cy="289.77032"
        rx="24.147528"
        ry="26.546288"
        style={{ fill: brown }} />
      <ellipse
        id="path16"
        cx="404.3212"
        cy="286.36279"
        rx="53.927471"
        ry="62.040039"
        transform="rotate(-6.250955)"
        style={{ fill: orange }} />
      <path
        d="m 340.62143,230.65541 c 1.56555,4.15429 0.43953,8.76649 -1.38013,12.64328 -0.66103,1.45805 -1.2758,3.06083 -2.70235,3.9478 -3.07205,2.38579 -7.42532,3.48751 -11.09145,1.84499 -3.01319,-1.26673 -5.32285,-3.7357 -7.1669,-6.36666 -1.51007,-4.39573 -1.87569,-9.54951 0.51371,-13.70997 2.16331,-3.65047 6.34092,-5.53004 11.10387,-5.43614 4.76295,0.0939 9.06418,2.77289 10.72325,7.0767 z"
        id="path14"
        style={{ fill: brown }} />
      <circle
        id="path17"
        cx="537.29956"
        cy="317.88702"
        r="23.247284"
        style={{ fill: brown }} />
      <circle
        id="circle17"
        cx="569.16663"
        cy="319.97665"
        r="15.933531"
        style={{ fill: brown }} />
      <ellipse
        id="ellipse17"
        transform="rotate(-16.202918)"
        style={{ fill: brown }}
        ry="58.64999"
        rx="45.97105"
        cy="354.04968"
        cx="340.7012" />
      <ellipse
        id="ellipse18"
        transform="rotate(-16.202918)"
        style={{ fill: deepBrown }}
        ry="35.617847"
        rx="26.475977"
        cy="353.69818"
        cx="349.7196" />
    </svg>
  )
}

